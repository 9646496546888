import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AUTH_HEADER } from "../../../store/actions/types";
import NavSidebar from "../../../components/NavSidebar/NavSidebar";
import { getUserInfo } from "../../../store/actions/userInfo";

const Verification = ({ dispatch, user }) => {
  useEffect(() => {
    dispatch({ type: AUTH_HEADER });
    if (user) {
      dispatch(getUserInfo());
    }
  }, []);

  return (
    <div className="verification">
      <div className="ellipse--bottom"></div>
      <div className="container">
        <NavSidebar />

        <div className="verification__wrap">
          <div className="verification__main">
            <div className="verification__center">
              <div className="verification__block">
                <div className="verification__title">
                  Ми перевіряємо Ваші дані
                </div>
                <div className="verification__text">
                  Щойно Ваш профіль буде верифіковано, Ви отримаєте
                  лист-сповіщення. Дякуємо за реєстрацію!
                </div>
                <div className="verification__content">
                  <div className="verification__line">
                    <div className="verification__steps">
                      <div className="verification__step _active">
                        <div className="verification__step-point"></div>
                        <div className="verification__step-content">
                          <div className="verification__step-number">01.</div>
                          <div className="verification__step-text">
                            Отримано
                          </div>
                        </div>
                      </div>
                      <div className="verification__step ">
                        <div className="verification__step-point"></div>
                        <div className="verification__step-content">
                          <div className="verification__step-number">02.</div>
                          <div className="verification__step-text">
                            На погодженні
                          </div>
                        </div>
                      </div>
                      <div className="verification__step ">
                        <div className="verification__step-point"></div>
                        <div className="verification__step-content">
                          <div className="verification__step-number">03.</div>
                          <div className="verification__step-text">
                            Погоджено
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="verification__button">
                  <Link to="/profile" className="verification__link">
                    Редагувати дані
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps)(Verification);

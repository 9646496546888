import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Icon from "../../UI/Icon";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import dropdown from "../../assets/dropdown.svg";
import { Switch } from "antd";
import Button from "../../UI/Button";
import DatePickerSelect from "../blocks/DatePickerSelect";
import TableConsumption from "../blocks/TableConsumption";
import TableIndicator from "../blocks/TableIndicator";
import SelectBlock from "../blocks/SelectBlock";
import moment from "moment";
import { connect } from "react-redux";
import {
  getPricesRange,
  getConsumptionByPoint,
} from "../../store/actions/userInfo";
import {
  getAveragePricePerDate,
  getAveragePricePerHourly,
} from "../../store/actions/calculation";
import { cleanFilteredItems } from "../../store/actions/filters";
import {
  GET_FILTERS_ASKOE,
  GET_FILTERS_ASKOE_SWITCH,
} from "../../store/actions/types";
import FilterBlockTablet from "../blocks/FilterBlockTablet";
import ModalLayout from "../../template/ModalLayout";
import { utils, writeFile } from "xlsx";
import ChartAskoe from "../blocks/ChartAskoe";

const AskoeModal = ({
  setIsModalAskoeVisible,
  isModal,
  item,
  dispatch,
  averagePrice,
  averagePriceHourly,
  userInfo,
  filters,
  consumptionSelected,
  checked,
}) => {
  const [dropdownIndicatorState, setDropdownIndicatorState] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [controlSection, setControlSection] = useState(true);
  const [indicator, setIndicator] = useState("Споживання");
  const [headings, setHeadings] = useState(null);
  const [rowsHourly, setRowsHourly] = useState(null);
  const [rowsDaily, setRowsDaily] = useState(null);
  const [rowsIndicator, setRowsIndicator] = useState(null);
  const [eventsSelect, setEventsSelect] = useState("auto");
  const setStartHours = moment().set({
    hours: 0,
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const [direction, setDirection] = useState({
    label: consumptionSelected,
    value: consumptionSelected,
  });

  const [firstMonth, setFirstMonth] = useState(null);
  const [lastMonth, setLastMonth] = useState(null);
  const hoursRows = [];
  const ref = useRef();

  //for Chart
  const hoursChart = [];
  const dailyChart =
    rowsDaily && rowsDaily.map((item) => moment(item.date).format("DD.MM"));
  const consumptionHourlyChart =
    rowsHourly &&
    rowsHourly.map((item) => item.consumption.reduce((a, b) => a + b));
  const consumptionDailyChart =
    rowsDaily && rowsDaily.map((item) => item.consumption);

  const handlerRemove = useCallback(() => {
    if (indicator == "Показники") {
      dispatch({
        type: GET_FILTERS_ASKOE,
        payload: { consumptionSelected: null },
      });
      setDirection(null);
    }

    dispatch(cleanFilteredItems());
  }, []);

  useEffect(() => {
    dispatch(cleanFilteredItems());
  }, [checked, controlSection, indicator, isModal]);

  useMemo(() => {
    if (item && firstMonth && lastMonth) {
      dispatch(getConsumptionByPoint(item.id, firstMonth, lastMonth)).then(
        (response) => {
          let sortedData = _.sortBy(response.data, "date");
          let consumptionAPlus = [],
            consumptionAMinus = [],
            defaultCounterList = [],
            headings = [];
          let rows = [];

          if (indicator == "Показники") {
            headings = [
              "Точка доступу",
              "Напрямок",
              "Початкові показники, кВт*год",
              "Кінцеві показники, кВт*год",
              "Коефіцієнт трансформації",
              "Споживання, кВт*год",
            ];
            if (sortedData.length > 0) {
              const counterAPlus = sortedData.reduce((obj, arr, i) => {
                obj["A+"]
                  ? arr.counterAPlus && obj["A+"].counter.push(arr.counterAPlus)
                  : (obj["A+"] = {
                      consumptionSelected: "A+",
                      accessPoint: arr.accessPoint.name,
                      counter: [arr.counterAPlus],
                      coef: arr.accessPoint.coefficient,
                    });
                return obj;
              }, {});

              const counterAMinus = sortedData.reduce((obj, arr, i) => {
                obj["A-"]
                  ? arr.counterAMinus &&
                    obj["A-"].counter.push(arr.counterAMinus)
                  : (obj["A-"] = {
                      consumptionSelected: "A-",
                      accessPoint: arr.accessPoint.name,
                      counter: [arr.counterAMinus],
                      coef: arr.accessPoint.coefficient,
                    });
                return obj;
              }, {});

              defaultCounterList = Object.values(counterAPlus).concat(
                Object.values(counterAMinus)
              );
            }
            if (consumptionSelected) {
              const filteredCounterList = defaultCounterList.filter(
                (item) => item.consumptionSelected === consumptionSelected
              );
              setRowsIndicator(filteredCounterList);
            } else {
              setRowsIndicator(defaultCounterList);
            }

            setHeadings(headings);
          } else if (checked && indicator == "Споживання") {
            let groupedItems = null;

            if (sortedData.length > 0) {
              headings = sortedData.map((item) => {
                return moment(item.date).format("DD.MM");
              });

              consumptionAPlus = sortedData
                .map((item) => _.sortBy(item.consumptionAPlus, "time"))
                .map((item) =>
                  item.map((arr) => ({
                    ...arr,
                    consumption: +arr.consumption?.toFixed(2),
                  }))
                );

              consumptionAMinus = sortedData
                .map((item) => _.sortBy(item.consumptionAMinus, "time"))
                .map((item) =>
                  item.map((arr) => ({
                    ...arr,
                    consumption: +arr.consumption?.toFixed(2),
                  }))
                );

              consumptionSelected === "A+"
                ? (groupedItems = consumptionAPlus
                    .flat()
                    .reduce((obj, arr, i) => {
                      obj[arr.time]
                        ? obj[arr.time].consumption.push(arr.consumption)
                        : (obj[arr.time] = {
                            time: hoursRows[i],
                            consumptionSelected: "A+",
                            consumption: [arr.consumption],
                          });
                      return obj;
                    }, {}))
                : (groupedItems = consumptionAMinus
                    .flat()
                    .reduce((obj, arr, i) => {
                      obj[arr.time]
                        ? obj[arr.time].consumption.push(arr.consumption)
                        : (obj[arr.time] = {
                            time: hoursRows[i],
                            consumptionSelected: "A-",
                            consumption: [arr.consumption],
                          });
                      return obj;
                    }, {}));

              rows = Object.values(groupedItems);
            } else {
              for (
                let i = moment(firstMonth);
                i <= moment(lastMonth);
                i.add(1, "day")
              ) {
                headings.push(moment(i).format("DD.MM"));
              }
              for (let i = 0; i <= 23; i++) {
                consumptionSelected === "A+"
                  ? rows.push({
                      time:
                        moment(setStartHours).add(i, "hours").format("HH:mm") +
                        "-" +
                        moment(setStartHours)
                          .add(1 + i, "hours")
                          .format("HH:mm"),
                      consumptionSelected: "A+",
                      consumption: [...Array(headings.length)].map((item) => 0),
                    })
                  : rows.push({
                      time:
                        moment(setStartHours).add(i, "hours").format("HH:mm") +
                        "-" +
                        moment(setStartHours)
                          .add(1 + i, "hours")
                          .format("HH:mm"),
                      consumptionSelected: "A-",
                      consumption: [...Array(headings.length)].map((item) => 0),
                    });
              }
            }

            setRowsHourly(rows);
            setHeadings(headings);
          } else {
            if (indicator !== "Показники") {
              headings = [
                "Дата",
                "Точки доступу",
                "Напрямок",
                "Споживання, кВт*год.",
              ];
            }
            if (sortedData.length > 0) {
              consumptionAPlus = sortedData.map((item) => ({
                date: item.date,
                accessPoint: item.accessPoint.name,
                direction: "A+",
                consumption: +item.consumptionAPlus
                  .reduce((a, b) => {
                    return a + b.consumption;
                  }, 0)
                  .toFixed(2),
              }));

              consumptionAMinus = sortedData.map((item) => ({
                date: item.date,
                accessPoint: item.accessPoint.name,
                direction: "A-",
                consumption: +item.consumptionAMinus
                  .reduce((a, b) => {
                    return a + b.consumption;
                  }, 0)
                  .toFixed(2),
              }));

              consumptionSelected === "A+"
                ? setRowsDaily(consumptionAPlus)
                : setRowsDaily(consumptionAMinus);
            } else {
              for (
                let i = moment(firstMonth);
                i <= moment(lastMonth);
                i.add(1, "day")
              ) {
                consumptionAPlus.push({
                  date: moment(i).format("YYYY-MM-DD"),
                  accessPoint: item.name,
                  direction: "A+",
                  consumption: 0,
                });
                consumptionAMinus.push({
                  date: moment(i).format("YYYY-MM-DD"),
                  accessPoint: item.name,
                  direction: "A-",
                  consumption: 0,
                });
              }

              consumptionSelected === "A+"
                ? setRowsDaily(consumptionAPlus)
                : setRowsDaily(consumptionAMinus);
            }
            setHeadings(headings);
          }
        }
      );
    }
  }, [item, consumptionSelected, checked, firstMonth, lastMonth, indicator]);

  useMemo(() => {
    if (filters?.startDate && filters?.endDate) {
      setFirstMonth(moment(filters.startDate).format("YYYY-MM-DD"));
      setLastMonth(moment(filters.endDate).format("YYYY-MM-DD"));
    } else if (checked && !controlSection) {
      setFirstMonth(moment().format("YYYY-MM-DD"));
      setLastMonth(moment().format("YYYY-MM-DD"));
    } else {
      setFirstMonth(moment().startOf("month").format("YYYY-MM-DD"));
      setLastMonth(moment().endOf("month").format("YYYY-MM-DD"));
    }

    if (firstMonth && lastMonth) {
      dispatch(getPricesRange(firstMonth, lastMonth)).then((response) => {
        if (firstMonth === lastMonth && lastMonth !== null) {
          dispatch(
            getAveragePricePerHourly(
              response.data,
              userInfo.company.coefficient
            )
          );
        } else {
          dispatch(
            getAveragePricePerDate(response.data, userInfo.company.coefficient)
          );
        }
      });
    }
  }, [filters, firstMonth, lastMonth, checked, controlSection, indicator]);

  for (let i = 0; i <= 23; i++) {
    hoursRows.push(moment(setStartHours).add(i, "hours").format("HH:mm"));
    hoursChart.push(moment(setStartHours).add(i, "hours").format("HH:mm"));
  }

  const switchSector = () => {
    controlSection ? setControlSection(false) : setControlSection(true);
  };

  const switchIndicator = (e) => {
    if (e.target.innerText == "Показники") {
      setControlSection(true),
        dispatch({
          type: GET_FILTERS_ASKOE,
          payload: { consumptionSelected: null },
        });
      setDirection(null);
    } else {
      dispatch({
        type: GET_FILTERS_ASKOE,
        payload: { consumptionSelected: "A+" },
      });
      setDirection({ label: "A+", value: "A+" });
    }
    setDropdownIndicatorState(false), setIndicator(e.target.innerText);
  };

  const showIndicatorDropdown = (e) => {
    dropdownIndicatorState
      ? setDropdownIndicatorState(false)
      : setDropdownIndicatorState(true);
  };

  const handleExport = () => {
    const xlsHeadings =
      checked && indicator !== "Показники"
        ? [["Час", "Напрямок"].concat(headings)]
        : [headings];
    let xlsRows = null,
      xlsRowsIndicatios = null;
    checked
      ? (xlsRows = rowsHourly.reduce((obj, arr) => {
          obj[arr.time] = [arr.time, arr.consumptionSelected].concat(
            arr.consumption
          );
          return obj;
        }, {}))
      : (xlsRows = Object.values(rowsDaily.map((item) => Object.values(item))));

    xlsRowsIndicatios = rowsIndicator?.reduce((obj, arr) => {
      obj[arr.consumptionSelected] = [
        arr.accessPoint,
        arr.consumptionSelected,
        arr.counter[0],
        arr.counter[arr.counter.length - 1],
        arr.coef,
        (arr.counter[arr.counter.length - 1] - arr.counter[0]) * arr.coef,
      ];
      return obj;
    }, {});

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, xlsHeadings);
    utils.sheet_add_json(
      ws,
      indicator !== "Показники"
        ? Object.values(xlsRows)
        : Object.values(xlsRowsIndicatios),
      { origin: "A2", skipHeader: true }
    );
    utils.book_append_sheet(wb, ws, "Order");
    writeFile(wb, `${item.name} ${firstMonth}-${lastMonth}.xlsx`);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setEventsSelect(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return (
    <>
      <div className="askoe-modal">
        <div className="askoe-modal__wrap">
          <div className="askoe-modal__body">
            <div
              className="modal__close"
              onClick={() => setIsModalAskoeVisible(false)}
            ></div>
            <div className="askoe-modal__top">
              <div className="askoe-modal__title">{item.name}</div>
              <div className="general-info__controls">
                <div
                  className={
                    !controlSection
                      ? "general-info__controls-section--active"
                      : "general-info__controls-section"
                  }
                  style={{ pointerEvents: indicator === "Показники" && "none" }}
                  onClick={switchSector}
                >
                  Графік
                </div>
                <div className="general-info__separator"></div>
                <div
                  className={
                    controlSection
                      ? "general-info__controls-section--active"
                      : "general-info__controls-section"
                  }
                  onClick={switchSector}
                >
                  Таблиця
                </div>
              </div>
            </div>

            <div className="askoe-modal__filter">
              <div className="select-block">
                <div
                  className="select-block__filter"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  {" "}
                  <span>
                    <Filter />
                  </span>
                  Фільтри
                </div>
                <div className="select-block__item">
                  <div className="askoe-modal__datapicker">
                    <DatePickerSelect
                      datepicker
                      switchState={false}
                      placeholder="Період"
                      setEventsSelect={setEventsSelect}
                      controlSection={controlSection}
                      checked={checked}
                      firstMonth={firstMonth}
                      noDisabled={true}
                    />
                  </div>
                  <div className="select-block__item" ref={ref}>
                    <SelectBlock
                      items={[
                        { label: "A+", value: "A+" },
                        { label: "A-", value: "A-" },
                      ]}
                      value={direction}
                      event={eventsSelect}
                      placeholder="Напрямок"
                      onChange={(selected) => (
                        setDirection(selected),
                        dispatch({
                          type: GET_FILTERS_ASKOE,
                          payload: { consumptionSelected: selected.value },
                        })
                      )}
                    />
                  </div>

                  {item.parentPoint && (
                    <div
                      className="select-block__dropdown"
                      onClick={showIndicatorDropdown}
                      ref={ref}
                    >
                      <div className="select-block__dropdown-wrap">
                        <Icon
                          img={dropdown}
                          className={
                            dropdownIndicatorState
                              ? "icon__dropdown--open icon__dropdown"
                              : "icon__dropdown"
                          }
                        />
                        {indicator}
                        {dropdownIndicatorState && (
                          <div
                            className="select-block__dropdown-item"
                            onClick={switchIndicator}
                          >
                            {indicator === "Споживання"
                              ? "Показники"
                              : "Споживання"}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {indicator === "Споживання" && (
                    <div className="askoe-modal__switch">
                      <Switch
                        checked={checked}
                        onClick={() => {
                          checked
                            ? dispatch({
                                type: GET_FILTERS_ASKOE_SWITCH,
                                payload: { checked: false },
                              })
                            : dispatch({
                                type: GET_FILTERS_ASKOE_SWITCH,
                                payload: { checked: true },
                              });
                        }}
                      />
                      Погодинно
                    </div>
                  )}
                </div>
                <div className="select-block__clear" onClick={handlerRemove}>
                  {" "}
                  <span className="select-block__close"></span> Очистити фільтр
                </div>
              </div>
            </div>
            {controlSection || indicator == "Показники" ? (
              <div className="general-info__table">
                {indicator == "Показники" ? (
                  <TableIndicator headings={headings} rows={rowsIndicator} />
                ) : (
                  <TableConsumption
                    checked={checked}
                    headings={headings}
                    rows={checked ? rowsHourly : rowsDaily}
                  />
                )}
              </div>
            ) : (
              <div className="askoe-modal__chart">
                <ChartAskoe
                  className="chart__askoe"
                  askoe
                  xAvis={checked ? hoursChart : dailyChart}
                  consumption={
                    checked ? consumptionHourlyChart : consumptionDailyChart
                  }
                  averagePrice={checked ? averagePriceHourly : averagePrice}
                />
              </div>
            )}
            <div className="askoe-modal__button">
              <Button
                text="Експортувати дані"
                className="button button__excel"
                sendRequest={handleExport}
              ></Button>
            </div>
          </div>
        </div>
      </div>

      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          children={
            <FilterBlockTablet
              setIsModalVisible={setIsModalVisible}
              noDisabled
              datepicker
              placeholder="Період"
              controlSection={controlSection}
              setControlSection={setControlSection}
              checked={checked}
              indicatorType={item.parentPoint}
              direction={direction}
              setDirection={setDirection}
              indicator={indicator}
              setIndicator={setIndicator}
            />
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo,
    { averagePrice } = state.calculation,
    { averagePriceHourly } = state.calculation,
    { filters, consumptionSelected, checked } = state.filters;

  return {
    userInfo,
    averagePrice,
    averagePriceHourly,
    filters,
    consumptionSelected,
    checked,
  };
}
export default connect(mapStateToProps)(AskoeModal);

import React, {useState, useEffect} from 'react';
import TableOrder from './TableOrder';
import { getConsumptionOrderByContract} from "../../store/actions/userInfo";
import { connect } from "react-redux";
import moment from 'moment';



const OrderItem = ({itemOpen, contract,  averagePriceDay,  askoePoint, dispatch, orderIndex}) => {
	const [orderItemState, setOrderItemState] = useState(orderIndex !== 0 && itemOpen ? true : false);
	const [orderItems, setOrderItems] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const openOrderItem =() => {
		orderItemState ? setOrderItemState(false) : setOrderItemState(true)
	}
	
	useEffect(() => {
		
		if(!askoePoint){
			dispatch(getConsumptionOrderByContract(contract.id ,moment().startOf('month').format('YYYY-MM-DD'), moment().add(3,'M').startOf('month').format('YYYY-MM-DD'))).then(response => {
				
				const groupConsumption = [];
				const orderMonths = [];
					Object.values(Object.assign({}, response.data)).forEach((item) => {
						groupConsumption[moment(item.month).format('YYYY-MM')] ? groupConsumption[moment(item.month).format('YYYY-MM')].totalMonthConsumption+=item.totalMonthConsumption  : groupConsumption[moment(item.month).format('YYYY-MM')] = item;				
					});
					
					let sortedItem = _.sortBy(Object.values(groupConsumption), 'month');
							for(let i=0; i<3; i++) {
								orderMonths.push({'month': moment().startOf('month').add(i,'M').format('YYYY-MM-DD'), 'totalMonthConsumption': null}) 
							}
							
					let newItem = orderMonths.map(item => sortedItem.find(date => date.month === item.month) || item );
				
					setOrderItems(newItem)		
			})
		}
		
	}, [isModalVisible, itemOpen])

	
	return (
	<>
	{(contract || askoePoint) && 
			<div className="order-energy__item" >
			 	<div className="order-energy__item-wrap">
			 		{itemOpen ?
			 		<>
						<div className="order-energy__item-point" onClick={openOrderItem}> <div className={orderItemState ? "order-energy__item-arrow order-energy__item-arrow--open" : "order-energy__item-arrow "}></div></div>
						<div className="order-energy__item-contract">Договір №{askoePoint.contractNumber ? askoePoint.contractNumber : askoePoint.contract.contractNumber}</div>
						<div className="order-energy__item-number">{askoePoint.name}</div> 
						<div className="order-energy__item-adress">{askoePoint.contractAddress ? askoePoint.contractAddress  : askoePoint.contract.contractAddress }</div>
					</>
					:
					<>
						<div className="order-energy__item-number">Договір №{contract.contractNumber}</div>
						<div className="order-energy__item-adress">{contract.contractAddress}</div>
					</>
					}
				
						
						
						{itemOpen ?
							!orderItemState && <TableOrder point={point} orderItems ={orderItems} averagePriceDay={averagePriceDay} askoe={askoePoint} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
							: 
			 				<TableOrder orderItems ={orderItems} averagePriceDay={averagePriceDay} contract={contract} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>
						
			 			}
			 	</div>
			</div>
			
	}
	</>
	)}

		

	export default connect()(OrderItem);
import {
	AUTH_HEADER,
	NONAUTH_HEADER,
  } from "../actions/types";
  
  const initialState = {
	isHeader: false
  }
 

  export default (state = initialState, action) => {
	const { type } = action;
	switch(type){
	  case AUTH_HEADER:
		return { ...state, isHeader: true } ;
	  case NONAUTH_HEADER:
		return { ...state, isHeader: false };
	  default:
		return state;
	}
  };
import React from "react";
import PropTypes from "prop-types";
import Button from "../UI/Button";
import { Link } from "react-router-dom";



const SidebarLayout = ({link, children}) => {
	return (
		<div className="registration__left1">
    		
				<div className="registration__sidebar">
				{link && 
					<Link to={link} >
						<Button text={window.innerWidth >= 1280 || window.innerWidth <= 768 ? 'Назад' : null} className="button__prev"/>
					</Link>
				}
					
					{children}
				</div>	
					
				
			
    	</div>
		
	)
  };

  SidebarLayout.propTypes = {
	children: PropTypes.node,
	link: PropTypes.node,
  };
  
  export default SidebarLayout;
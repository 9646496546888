import React from 'react';
import Button  from '../../UI/Button';
import moment from 'moment';






const TableConsumption = ({button, headings, rows, checked}) => {

	 

	return (

		<>
			<div className="general-info__measure">
				{button ? 
					<div className="general-info__table-button">
						<Button text={button} className='button button__excel'></Button> 
					</div>
					: null
				}
			</div>

			<div className='table-consumption'>
				<table >
					<thead>
						<tr>
						{checked ?
							<>
								<th className="table-consumption__th">Час</th>
								<th className="table-consumption__th">Напрямок</th>
								{headings && headings.map((date, i)=> <th key={i} className="table-consumption__th">{date}</th>)}
							</>
							:
							<>
								{headings && headings.map((date, i)=> <th key={i} className="table-consumption__th">{date}</th>)}
							</>
						}
						</tr>
					</thead>
					<tbody>
					{checked ?
						<>
							{rows && rows.map((row, i) => 
								<tr key={i}>
									<th className="table-consumption__th">{row.time}</th>
									<td className="table-consumption__td">{row.consumptionSelected}</td>
									{row.consumption.map((consumption, i) => 
										<td key={`${consumption}_${i}`} className="table-consumption__td">{consumption.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').split('.').map((s, i) => i == 0 ? s : s.replace(/\s+/g, '')).join('.') || ''}</td> 
									
									)}
								</tr>
							)}
						</>
							:
						<>
							{rows && rows.map((row, i) => 
								<tr key={i}>
									<th className="table-consumption__th">{moment(row.date).format('DD.MM')}</th>
									<td className="table-consumption__td">{row.accessPoint}</td>
									<td className="table-consumption__td">{row.direction}</td>
									<td className="table-consumption__td">{row.consumption?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
								</tr>
							)}
						</>
					}
					</tbody>
				
				</table>
			</div>
			{button ? 
					<div className="general-info__table-button--mb">
						<Button text={button} className='button button__excel'></Button> 
					</div>
					: null
				}
		
		</>
		
	
	)}

  export default TableConsumption;
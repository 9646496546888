import React from 'react';
import Button from '../../../UI/Button';
import ContentSignIn from './ContentSignIn';
import RegistrationLayout from '../../../template/RegistrationLayout';
import SidebarLayout from '../../../template/SidebarLayout';




const SignUp = () => {

	return (
	<RegistrationLayout link='/'>
		<div className="registration__main">
			<SidebarLayout link='/'/>
			<ContentSignIn/>
		</div>
	</RegistrationLayout>
	
				


		
	)
  }

  export default SignUp;
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const SHOW_DESKTOP_SIDEBAR = "SHOW_DESKTOP_SIDEBAR";
export const HIDE_DESKTOP_SIDEBAR = "HIDE_DESKTOP_SIDEBAR";
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";

export const AUTH_HEADER = "AUTH_HEADER";
export const NONAUTH_HEADER = "NONAUTH_HEADER";

export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const GET_USER = "GET_USER";
export const GET_ITEMS = "GET_ITEMS";
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTERED_ITEMS = "GET_FILTERED_ITEMS";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_ASKOE_POINTS = "GET_ASKOE_POINTS";
export const GET_CONTRACT = "GET_CONTRACT";

export const GET_AVERAGE_PRICE = "GET_AVERAGE_PRICE";
export const GET_AVERAGE_PRICE_HOURLY = "GET_AVERAGE_PRICE_HOURLY";

export const GET_FILTERS_ASKOE = "GET_FILTERS_ASKOE";
export const GET_FILTERS_ASKOE_SWITCH = "GET_FILTERS_ASKOE_SWITCH";

export const GET_GENERAL_CONSUMPTION = "GET_GENERAL_CONSUMPTION";

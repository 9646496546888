import React, {useState} from 'react';
import ContentSignUp from './ContentSignUp';
import RegistrationLayout from "../../../template/RegistrationLayout";
import SidebarLayout from '../../../template/SidebarLayout';




const SignUp = ({}) => {
	
	return (
	<RegistrationLayout link='/'>
		<div className="registration__main">
			<SidebarLayout link='/'>
				<div className="registration__sidebar-title">Запит на укладання договору</div>
				<div className="registration__sidebar-text">Щоб стати нашим клієнтом, Вам необхідно заповнити всі поля та надіслати запит. Ми розглянемо його й повернемось із відповіддю протягом 24 годин.</div>
			</SidebarLayout>
			<ContentSignUp />
		</div>
	</RegistrationLayout>
	

		
	)
  }


  export default SignUp;
import React from 'react';
import Select from "react-select";
import PropTypes from "prop-types";





const SelectBlock = ({items, defaultValue, selectStyle, onChange, placeholder, value, isDisabled, event}) => {


	const colourStyles = {
		control: (styles) => ({ ...styles,
			width: '165px',
			fontSize:'14px' ,
			lineHeight:'20px' ,
			fontWeight: '700',
			fontFamily: 'IBMPlexSans',
			color: "#111111",
			padding:'5px 10px 5px 0',
			textAlign:'center',
			backgroundColor: "#F0F2F7", 
			boxShadow:" -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4)",
			borderRadius: "47.1945px" ,
			border: "none",
			opacity: isDisabled ? '0.6' : '1',
			cursor:'pointer',
			pointerEvents: event }),

			dropdownIndicator: (base, state) => ({
				...base,
				transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
				transition: '250ms',
				color: "#111111",
			  }),
		
			option: (styles) => {
		  return {
			...styles,
			width: '169px',
			backgroundColor: "#F0F2F7",
			borderRadius: "16px" ,
			color: "#111111",
		  };
		}
	  };

	const selectStyles = {
		control: (styles) => ({ ...styles,
			
			fontSize:'14px' ,
			lineHeight:'20px' ,
			fontWeight: '600',
			color: "#111111",
			padding:'6px 0 6px 6px',
			textAlign:'left',
			backgroundColor:"#FFFFFF", 
			boxShadow:"inset -1px -1px 1px rgba(255, 255, 255, 0.7), inset 1px 1px 2px rgba(174, 174, 192, 0.2)",
			borderRadius: "5px" ,
			border: "none",
			cursor:'pointer'}),

			dropdownIndicator: (base, state) => ({
				...base,
				transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
				transition: '250ms',
				color: "#111111",
			  }),
		
			option: (styles) => {
		  return {
			...styles,
			padding:'8px 0',
			fontSize:'14px' ,
			lineHeight:'20px' ,
			fontWeight: '600',
			backgroundColor: "#FFFFFF",
			borderRadius: "5" ,
			border:'none',
			boxShadow:'none',
			outline:'none',
			color: "#111111",
			cursor:'pointer'
		  };
		}
	  };

	

	return (
		<Select
			value={value}
			label="Single select"
			options={items}
			defaultValue={defaultValue}
			styles={!selectStyle ? colourStyles :  selectStyles}
			isSearchable={false}
			onChange={onChange}
			placeholder={placeholder}
			isDisabled={isDisabled}
			className="react-select-container"
  			classNamePrefix="react-select"
			// menuIsOpen={true}
		/>
	)}


	SelectBlock.propTypes = {
		items: PropTypes.array
		
	};
  export default SelectBlock;


  
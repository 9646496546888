import React, { useEffect, useState } from "react";
import ModalLayout from "../../template/ModalLayout";
import InformModal from "../../components/modals/InformModal";
import Button from "../../UI/Button";
import { Form, Input, Switch } from "antd";
import { ReactComponent as Tooltip } from "../../assets/tooltip.svg";
import { connect } from "react-redux";

import { updateUserInfo } from "../../store/actions/userInfo";

const ProfileData = ({ userInfo, dispatch }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("Поле обов`язкове для заповнення");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: userInfo.firstName,
    middleName: userInfo.middleName,
    lastName: userInfo.lastName,
    companyName: userInfo.companyName,
    companyFullName: userInfo.companyFullName,
    notifications: userInfo.notifications,
  });

  const handlerSubmit = (e) => {
    setButtonDisable(true);
    form
      .validateFields()
      .then((values) => {
        Object.keys(values).map(function (key, index) {
          if (values[key] == null) {
            delete values[key];
          }
          return values[key];
        });
        delete values["approvePassword"];
        dispatch(updateUserInfo(values, userInfo.id))
          .then((response) => {
            (values["password"] = ""),
              (values["approvePassword"] = ""),
              setButtonDisable(false);
            setIsModalVisible(true);
            setInitialValues({
              firstName: response.firstName,
              middleName: response.middleName,
              lastName: response.lastName,
              companyName: response.companyName,
              companyFullName: response.companyFullName,
              notifications: response.notifications,
            });
          })
          .catch((error) => {
            setButtonDisable(false);
          });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (userInfo) {
      setInitialValues({
        firstName: userInfo.firstName,
        middleName: userInfo.middleName,
        lastName: userInfo.lastName,
        companyName: userInfo.companyName,
        companyFullName: userInfo.companyFullName,
        notifications: userInfo.notifications,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <>
      <div className="profile">
        <div className="profile__wrap">
          <Form
            form={form}
            onFinish={handlerSubmit}
            name="profile-data"
            initialValues={initialValues}
          >
            <div className="profile__blocks">
              <div className="profile__block">
                <div className="profile__block-part">
                  <div className="profile__item-user">
                    <div className="profile__item">
                      <Form.Item
                        name="lastName"
                        rules={[
                          { required: true, message: error },
                          {
                            pattern:
                              /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                            message: "Невірний формат",
                          },
                        ]}
                      >
                        <Input placeholder="Прізвище" />
                      </Form.Item>
                    </div>
                    <div className="profile__item">
                      <Form.Item
                        name="firstName"
                        rules={[
                          { required: true, message: error },
                          {
                            pattern:
                              /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                            message: "Невірний формат",
                          },
                        ]}
                      >
                        <Input placeholder="Ім’я" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="profile__item">
                    <Form.Item
                      name="middleName"
                      rules={[
                        { required: true, message: error },
                        {
                          pattern:
                            /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                          message: "Невірний формат",
                        },
                      ]}
                    >
                      <Input placeholder="По батькові" />
                    </Form.Item>
                  </div>
                  <div className="profile__item">
                    <Form.Item
                      name="companyFullName"
                      rules={[
                        { required: true, message: error },
                        {
                          pattern:
                            /^([^\s])([А-я0-9'-єЄіІїЇёЁґҐ"'`!#$%&*+/=?^{|}~._\s)]{1,})$/,
                          message: "Невірний формат",
                        },
                      ]}
                    >
                      <Input placeholder="Введіть повну назву компанії" />
                    </Form.Item>
                  </div>
                  <div className="profile__item">
                    <Form.Item
                      name="companyName"
                      rules={[
                        { required: true, message: error },
                        {
                          pattern:
                            /^([^\s])([А-я0-9'-єЄіІїЇёЁґҐ"'`!#$%&*+/=?^{|}~._\s)]{1,})$/,
                          message: "Невірний формат",
                        },
                      ]}
                    >
                      <Input placeholder="Введіть скорочену назву компанії" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="separator"></div>
              <div className="profile__block">
                <div className="profile__block-part">
                  <div className="profile__item">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          pattern: /^([A-z0-9_\.]{8,12})$/,
                          message:
                            "Пароль має бути не менше 8 і не довше 12 символів, a-z, A-Z (латиница), цифри 0-9, крапка (.), символи (_)",
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="off"
                        placeholder="Введіть новий пароль"
                      />
                    </Form.Item>
                    <span className="profile__item--tooltip">
                      <Tooltip />
                    </span>
                  </div>
                  <div className="profile__item">
                    <Form.Item
                      name="approvePassword"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "Введені вами паролі не збігаються!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        autoComplete="off"
                        placeholder="Підтвердіть пароль"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="separator separator--tb"></div>
              </div>
              <div className="separator"></div>
              <div className="profile__switches-block">
                <div className="profile__item-text">Налаштування сповіщень</div>
                <div className="profile__switches-wrap">
                  <div className="profile__switch-item">
                    <Form.Item
                      label="Загальні сповіщення"
                      className="ant-form-item-switch"
                      name="notifications"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="profile__button">
              <Button
                disabled={buttonDisable}
                text="Зберегти"
                type="submit"
                className=" button button__full"
              />
            </div>
          </Form>
        </div>
      </div>

      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          className="background"
          children={
            <InformModal
              setIsModalRequestVisible={setIsModalVisible}
              title="Успішно змінено"
            />
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(ProfileData);

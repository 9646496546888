import Button from "../../UI/Button";

const ErrorModal = ({ setIsModalRequestVisible, text }) => {
  return (
    <div className="error-modal">
      <div className="error-modal__wrap">
        <div className="error-modal__body">
          <div
            className="modal__close"
            onClick={() => setIsModalRequestVisible(false)}
          ></div>
          <div className="error-modal__sent-content">
            <div className="inform-modal__text">{text}</div>
          </div>
          <Button
            text="Зрозуміло"
            className="button button__submit"
            sendRequest={() => setIsModalRequestVisible(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;

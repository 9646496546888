import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import { ReactComponent as Arrow } from "../../assets/arrow-calendar.svg";
import { ReactComponent as Arrow2 } from "../../assets/arrow2.svg";
import SkeletonRect from "./SkeletonRect";
import ReactPaginate from "react-paginate";
import FileSaver from "file-saver";
import moment from "moment";
import { requestsUrl } from "../../constants/contants";

const TableDocuments = ({ data, className, headings }) => {
  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + 5;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / 5));
    }
  }, [itemOffset, data]);

  useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % data.length;

    setItemOffset(newOffset);
  };

  const saveFile = (url, name) => {
    FileSaver.saveAs(url, name);
  };

  if (data === null) {
    return <SkeletonRect />;
  }

  return (
    <>
      <div className={className}>
        <table className="table-documents">
          <thead>
            <tr>
              {headings.map((date, i) => (
                <th key={i} className="table-documents__th">
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-documents__body">
            {currentItems &&
              currentItems.map((item, i) => (
                <tr key={i}>
                  <th className="table-documents__th">
                    {moment(item.documentDate).format("DD.MM.YY")}
                  </th>
                  <td className="table-documents__td">
                    {item.documentNumber}{" "}
                  </td>
                  {item.documentType === "act" ? (
                    <>
                      <td className="table-documents__td">
                        {item.financeInfo.debit
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                        грн
                      </td>
                      <td className="table-documents__td">
                        {item.financeInfo.credit
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                        грн
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="table-documents__td">
                        {item.financeInfo.mvtgod
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                      </td>
                      <td className="table-documents__td">
                        {item.financeInfo.cenaza1mvt
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                          .split(".")
                          .map((s, i) => (i == 0 ? s : s.replace(/\s+/g, "")))
                          .join(".") || ""}{" "}
                        грн
                      </td>
                      <td className="table-documents__td">
                        {item.financeInfo.summaznds
                          .toFixed(2)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                        грн
                      </td>
                    </>
                  )}
                  {item.attachment ? (
                    <td
                      className="table-documents__td table-documents__td--file"
                      onClick={() =>
                        saveFile(
                          requestsUrl.url + item.attachment.url,
                          item.attachment.name
                        )
                      }
                    >
                      {" "}
                      <Upload />
                    </td>
                  ) : (
                    <td className="table-documents__td table-documents__td--file"></td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ReactPaginate
        className="pagination"
        nextLabel={<Arrow />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={<Arrow2 />}
        marginPagesDisplayed={3}
        forcePage={currentPage}
      />
    </>
  );
};

export default TableDocuments;

import React from 'react';
import Button from '../../../UI/Button';
import ContentNewPassword from './ContentNewPassword';
import {Link} from "react-router-dom";
import RegistrationLayout from '../../../template/RegistrationLayout';
import SidebarLayout from '../../../template/SidebarLayout';




const NewPassword = () => {

	return (
		<RegistrationLayout link='/sign-in'>
			<div className="registration__main">
				<SidebarLayout link='/sign-in'>
					<div className="registration__sidebar-title">Заміна пароля</div>
					<div className="registration__sidebar-text">Перейшовши за посиланням і потрапивши на цей екран, ви успішно скинули старий пароль. Для створення нового вам потрібно заповнити цю форму.</div>
				</SidebarLayout>
				<ContentNewPassword/>
			</div>
		</RegistrationLayout>	
	)
  }

  export default NewPassword;
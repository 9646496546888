import React, { useState, useEffect, useMemo } from "react";
import closeSidebar from "../../assets/closeSidebar.svg";
import SelectBlock from "./SelectBlock";
import { connect } from "react-redux";
import DatePickerSelect from "./DatePickerSelect";
import Icon from "../../UI/Icon";
import dropdown from "../../assets/dropdown.svg";
import { registerLocale } from "react-datepicker";
import { Switch } from "antd";
import uk from "date-fns/locale/uk";
registerLocale("uk", uk);

import { getFilters, getFilteredItems } from "../../store/actions/filters";

import {
  GET_FILTERS_ASKOE,
  GET_FILTERS_ASKOE_SWITCH,
} from "../../store/actions/types";

const FilterBlockTablet = ({
  items,
  filters,
  dispatch,
  datepicker,
  contract,
  paymentType,
  noDisabled,
  checked,
  setIsModalVisible,
  placeholder,
  switchState,
  controlSection,
  setControlSection,
  direction,
  setDirection,
  indicator,
  setIndicator,
  indicatorType,
}) => {
  const [searchContract, setSearchContract] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [dropdownIndicatorState, setDropdownIndicatorState] = useState(false);
  const [eventsSelect, setEventsSelect] = useState("auto");

  useEffect(() => {
    if (searchContract || searchType) {
      dispatch(getFilters({ searchContract, searchType }));
    }
  }, [searchContract, searchType]);

  useEffect(() => {
    if (filters !== null) {
      dispatch(getFilteredItems(items, filters));
    }
  }, [filters]);

  const switchIndicator = (e) => {
    if (e.target.innerText == "Показники") {
      setControlSection(true),
        dispatch({
          type: GET_FILTERS_ASKOE,
          payload: { consumptionSelected: null },
        });
      setDirection(null);
    } else {
      dispatch({
        type: GET_FILTERS_ASKOE,
        payload: { consumptionSelected: "A+" },
      });
      setDirection({ label: "A+", value: "A+" });
    }
    setDropdownIndicatorState(false), setIndicator(e.target.innerText);
  };

  const showIndicatorDropdown = (e) => {
    dropdownIndicatorState
      ? setDropdownIndicatorState(false)
      : setDropdownIndicatorState(true);
  };

  const options = useMemo(() => {
    if (contract && items[0].contract) {
      return [
        ...new Set(items && items.map((item) => item.contract.contractNumber)),
      ];
    }
    return [...new Set(items && items.map((item) => item.contractNumber))];
  }, [items]);

  return (
    <>
      <div className="filter-block">
        <div className="filter-block__wrap">
          <div
            className="filter-block__close"
            onClick={() => setIsModalVisible(false)}
          >
            <img src={closeSidebar} alt="" />
          </div>
          <div className="filter-block__content">
            <div className="filter-block__title">Фільтри</div>
            <div className="separator"></div>
            <div className="filter-block__selects">
              <div className="select-block">
                {/* type */}
                {paymentType && (
                  <div className="select-block__item">
                    <SelectBlock
                      items={[
                        { label: "Нарахування", value: "income" },
                        { label: "Списання", value: "expense" },
                      ]}
                      value={searchType}
                      placeholder="Тип"
                      onChange={(selected) => setSearchType(selected)}
                      isDisabled={options.length > 0 ? false : true}
                      event={eventsSelect}
                    />
                  </div>
                )}
                {/* contract */}
                {contract && (
                  <div className="select-block__item">
                    <SelectBlock
                      items={options.map((item) => ({
                        label: `Договір № ${item}`,
                        value: item,
                      }))}
                      value={searchContract}
                      placeholder="Договір"
                      onChange={(selected) =>
                        setSearchContract({
                          label: `№ ${selected.value}`,
                          value: selected.value,
                        })
                      }
                      isDisabled={options.length > 0 ? false : true}
                      event={eventsSelect}
                    />
                  </div>
                )}

                {datepicker && (
                  <div className="select-block__item">
                    <DatePickerSelect
                      datepicker={datepicker}
                      switchState={switchState}
                      placeholder={placeholder}
                      setEventsSelect={setEventsSelect}
                      controlSection={controlSection}
                      checked={checked}
                      noDisabled={noDisabled}
                    />
                  </div>
                )}
                {/* askoe */}
                {noDisabled && (
                  <>
                    <div className="select-block__item">
                      <SelectBlock
                        items={[
                          { label: "A+", value: "A+" },
                          { label: "A-", value: "A-" },
                        ]}
                        value={direction}
                        placeholder="Напрямок"
                        onChange={(selected) => (
                          setDirection(selected),
                          dispatch({
                            type: GET_FILTERS_ASKOE,
                            payload: { consumptionSelected: selected.value },
                          })
                        )}
                      />
                    </div>
                    {indicatorType && (
                      <div
                        className="select-block__dropdown"
                        onClick={showIndicatorDropdown}
                      >
                        <div className="select-block__dropdown-wrap">
                          <Icon
                            img={dropdown}
                            className={
                              dropdownIndicatorState
                                ? "icon__dropdown--open icon__dropdown"
                                : "icon__dropdown"
                            }
                          />
                          {indicator}
                          {dropdownIndicatorState && (
                            <div
                              className="select-block__dropdown-item"
                              onClick={switchIndicator}
                            >
                              {indicator === "Споживання"
                                ? "Показники"
                                : "Споживання"}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="select-block__item">
                      {indicator === "Споживання" && (
                        <div className="askoe-modal__switch">
                          <Switch
                            checked={checked}
                            onClick={() => {
                              checked
                                ? dispatch({
                                    type: GET_FILTERS_ASKOE_SWITCH,
                                    payload: { checked: false },
                                  })
                                : dispatch({
                                    type: GET_FILTERS_ASKOE_SWITCH,
                                    payload: { checked: true },
                                  });
                            }}
                          />
                          Погодинно
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { items } = state.userInfo,
    { filters, checked, consumptionSelected } = state.filters;

  return {
    items,
    filters,
    checked,
    consumptionSelected,
  };
}

export default connect(mapStateToProps)(FilterBlockTablet);

import React from 'react';
import MainLayout from '../template/MainLayout';
import Button from '../UI/Button';
import { Link } from 'react-router-dom';

const PageNotFound = () => {



  return (

      <div className="pagenotFound">
        <div className=" pagenotFound__title">404</div>
        <Link to="/" className="">
							<Button   text='Повернутиля на головну' className="button button__full"></Button>
				</Link>
    
	 	 </div>

  );
};



export default PageNotFound;
import React, { useRef, useEffect } from "react";

import Button from "../../UI/Button";
import { Chart as ChartJS, BarElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { read, utils, writeFile } from "xlsx";

ChartJS.register(BarElement);

const ChartAskoe = (props) => {
  const ref = useRef();

  const data = {
    labels: props.xAvis,
    datasets: [
      {
        label: "Споживання, факт",
        data: props.consumption,
        backgroundColor: "rgba(255, 194, 18, 1)",
        borderRadius: 15,
        maxBarThickness: props.askoe ? 18 : 10,
        pointStyle: "circle",
        yAxisID: "yAxes",
      },
    ],
  };

  {
    props.averagePrice &&
      data.datasets.push({
        label: "Середня ціна РДН",
        data: props.averagePrice,
        backgroundColor: "rgba(202, 202, 202, 1)",
        borderWidth: 2,
        type: "line",
        pointStyle: "line",
        pointRadius: 0,
        border: "2px solid #D8D8D8",
        yAxisID: "averagePrice",
        xAxisID: "x2",
      });
  }

  const data2 = {
    labels: props.xAvis,
    datasets: [
      {
        label: "Середня ціна РДН",
        data: props.averagePrice,
        yAxisID: "price",
      },
    ],
  };

  const data3 = {
    labels: props.xAvis,
    datasets: [
      {
        label: "Споживання, факт",
        data: props.consumption,
        yAxisID: "yAxes",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },

    plugins: {
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
        },
      },
      labels: {
        display: true,
        boxWidth: 14,
        color: "rgba(79, 79, 79, 1)",
        usePointStyle: true,
        padding: {
          bottom: 59,
        },
      },
      tooltip: {
        intersect: false,
        backgroundColor: "rgba(79, 79, 79, 1)",
        borderColor: "rgba(255, 194, 18, 1)",
        borderWidth: 2,
        callbacks: {
          title: function (tooltipItem, data) {
            return (
              tooltipItem[1]
                ? (tooltipItem[1].formattedValue = tooltipItem[1].raw)
                : null,
              (tooltipItem[0].formattedValue = tooltipItem[0].raw
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")),
              tooltipItem[2]
                ? (tooltipItem[2].formattedValue =
                    tooltipItem[2].formattedValue.replace(/,/g, " "))
                : null,
              tooltipItem[0].label.split(",").slice(0, 1)
            );
          },
          label: function (tooltipItem) {
            let label = tooltipItem.dataset.label;
            let title = tooltipItem.formattedValue;

            return window.innerWidth < 768
              ? [label, title]
              : label + ": " + title;
          },
        },
      },
    },

    scales: {
      averagePrice: {
        type: "linear",
        position: "left",
        title: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },

      yAxes: {
        title: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },

      xAxes: {
        offset: true,

        grid: {
          display: false,
          drawOnChartArea: false,
          lineWidth: 0,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,

          display: true,
          color: "rgba(79, 79, 79, 1)",
          font: {
            size: 10,
          },
        },
      },

      x2: {
        display: false,
        offset: false,
      },
    },
  };

  const options2 = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 28,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      price: {
        type: "linear",
        position: "left",
        beginAtZero: true,
        title: {
          display: window.innerWidth < 1280 ? false : true,
          text: "Ціна грн/кВт*год",
          color: "rgba(170, 170, 170, 1)",
          font: {
            size: 12,
          },
          padding: { top: -2, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          display: false,
          borderColor: "rgba(170, 170, 170, 1)",
        },

        afterFit: (ctx) => {
          ctx.width = 75;
        },
      },
      xAxes: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const options3 = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 28,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        type: "linear",
        position: "right",
        beginAtZero: true,
        layout: {
          padding: {
            bottom: 59,
          },
        },
        title: {
          display: window.innerWidth < 1280 ? false : true,
          text: "Обсяг кВт*год",
          color: "rgba(170, 170, 170, 1)",
          font: {
            size: 12,
          },
          padding: { top: -2, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          display: false,
          borderColor: "rgba(170, 170, 170, 1)",
        },

        afterFit: (ctx) => {
          ctx.width = 75;
        },
      },
      xAxes: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const handleExport = () => {
    const xlsHeadings = [[""].concat(props.xAvis)];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, xlsHeadings);
    utils.sheet_add_aoa(ws, [["Споживання, факт"].concat(props.consumption)], {
      origin: "A2",
      skipHeader: true,
    });
    {
      props.averagePrice &&
        utils.sheet_add_aoa(
          ws,
          [["Середня ціна РДН"].concat(props.averagePrice)],
          { origin: "A3", skipHeader: true }
        );
    }
    {
      props.consumptionPlan &&
        utils.sheet_add_aoa(
          ws,
          [["Споживання, план"].concat(props.consumptionPlan)],
          { origin: "A4", skipHeader: true }
        );
    }
    utils.book_append_sheet(wb, ws, "consumption");
    writeFile(wb, `${props.title} .xlsx`);
  };

  return (
    <div className="chart__askoe-wrap">
      <div className="chart">
        <div className="chart__yaxes">
          <Bar data={data2} options={options2} />
        </div>

        <div className={props.className} ref={ref}>
          <div
            className={
              props?.xAvis?.length < 25 ? "chart__wrap" : "chart__wrap-scroll"
            }
          >
            <Bar data={data} options={options} />
          </div>
        </div>

        <div className="chart__yaxes2">
          <Bar data={data3} options={options3} />
        </div>
      </div>
      <div className="chart__bottom">
        <div className="chart__legend">
          <div className="chart__legend-list ">
            <div className="chart__legend-item chart__legend-item--fact">
              <span></span>
              Споживання, факт
            </div>
            {props.averagePrice && (
              <div className="chart__legend-item chart__legend-item--price">
                <span></span>
                Середня ціна РДН
              </div>
            )}
          </div>
        </div>
        {props.button ? (
          <div className="general-info__chart-button">
            <Button
              text={props.button}
              className="button button__excel"
              sendRequest={handleExport}
            ></Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChartAskoe;

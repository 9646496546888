import React, { useEffect } from "react";
import TableDocuments from "../../components/blocks/TableDocuments";
import { connect } from "react-redux";
import FilterBlockDesktop from "../../components/blocks/FilterBlockDesktop";
import { cleanFilteredItems } from "../../store/actions/filters";

const DocumentsAddContracts = ({
  documentsTableItems,
  filteredItems,
  dispatch,
}) => {
  const tableHeadings = [
    "Дата",
    "Номер",
    "Обсяг, кВт* год",
    "Ціна без ПДВ, 1 кВт*год",
    "Сума, з ПДВ",
    "Документ",
  ];

  useEffect(() => {
    dispatch(cleanFilteredItems(documentsTableItems));
  }, []);

  return (
    <div className="documents">
      <div className="documents__wrap">
        <div className="documents__title">Інформація за рахунками</div>
        <FilterBlockDesktop
          items={documentsTableItems}
          datepicker={true}
          placeholder="Рік і місяць"
        />

        <TableDocuments
          className="table__general"
          data={
            documentsTableItems &&
            filteredItems
              .filter((item) => item.documentType === "waybill")
              .sort(
                (a, b) => new Date(b.documentDate) - new Date(a.documentDate)
              )
          }
          headings={tableHeadings}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { filteredItems } = state.userInfo;

  return {
    filteredItems,
  };
}

export default connect(mapStateToProps)(DocumentsAddContracts);

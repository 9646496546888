import React from "react";
import Button from "../../UI/Button";

const InformModal = ({ setIsModalRequestVisible, title, text, text1 }) => {
  return (
    <div className="inform-modal">
      <div className="inform-modal__wrap">
        <div className="inform-modal__body">
          <div
            className="modal__close"
            onClick={() => setIsModalRequestVisible(false)}
          ></div>
          <div className="inform-modal__sent-content">
            <div className="inform-modal__title">{title}</div>
            <div className="inform-modal__text">{text}</div>
            <div className="inform-modal__text">{text1}</div>
          </div>
          <Button
            text="Зрозуміло"
            className="button button__submit"
            sendRequest={() => setIsModalRequestVisible(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default InformModal;

import React from 'react';
import PropTypes from "prop-types";
import { Modal } from 'antd';




const ModalLayout = ({className, children, isModalVisible, isPopupVisible}) => {
	

	return (
	<Modal
		className={className}
		visible={!isModalVisible  ? isPopupVisible : isModalVisible}
		
		footer={null}
		>
		{children}
    </Modal>
	)
  };
  

  ModalLayout.propTypes = {
	children: PropTypes.node,

  };
  export default ModalLayout;
import React from "react";
import MainLayout from "../../template/MainLayout";

import TariffStructureContent from "./TariffStructureContent";

const TariffStructure = () => {
  return (
    <MainLayout
      title="Вартість електроенергії"
      text="Тут міститься детальна інформація за рахунками та аналітика споживання за всіма договорами."
    >
      <TariffStructureContent />
    </MainLayout>
  );
};

export default TariffStructure;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { connect } from "react-redux";

const TabBlock = ({ tabs, className, userInfo }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={handleSelect}>
      {userInfo && userInfo.active ? (
        <TabList>
          {tabs.map(
            ({ tab }, index) =>
              tab && (
                <Tab key={index} className={className}>
                  {tab}
                </Tab>
              )
          )}
        </TabList>
      ) : (
        <TabList>
          {tabs.map(({ tab }, index) => (
            <Tab key={index} className={className} disabled>
              {tab}
            </Tab>
          ))}
        </TabList>
      )}

      {tabs.map(
        (tab, index) =>
          tab.сontent && <TabPanel key={index}>{tab.сontent}</TabPanel>
      )}
    </Tabs>
  );
};

TabBlock.propTypes = {
  tabs: PropTypes.array,
  className: PropTypes.node,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
};

export default connect(mapStateToProps, null)(TabBlock);

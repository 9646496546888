import React from 'react';
import MainLayout from '../../template/MainLayout';
import TabBlock from '../../components/blocks/Tab';
import ProfileData from './ProfileData';
import ProfileContacts from './ProfileContacts';
import ProfileDetails from './ProfileDetails';







const Profile = () => {
  return (
    <MainLayout title='Мій профіль' text='Тут міститься інформація про реквізити та персональні дані споживача, що використовуються у документообігу.' profile>
		<TabBlock   
			tabs={[	{tab:"Особисті дані", сontent: <ProfileData/>},
					// {tab: "Реквізити компанії", сontent: <ProfileDetails/>},
					// { tab: "Контакти", сontent: <ProfileContacts/>}
				]}
			  
		>
		</TabBlock>
	 </MainLayout>	
  );
};



export default  Profile;
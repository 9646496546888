import {
	SHOW_DESKTOP_SIDEBAR,
	HIDE_DESKTOP_SIDEBAR,
	SHOW_SIDEBAR,
	HIDE_SIDEBAR,

  } from "../actions/types";
  
  
  const initialState = 
  {
	visibleTbSidebar: false,
	sidebarDesktop: true,
  } 


 
  export default (state = initialState, action) => {
	const { type } = action;
	switch(type){
	  case SHOW_SIDEBAR:
		return { ...state, visibleTbSidebar: true } ;
	  case HIDE_SIDEBAR:
		return { ...state, visibleTbSidebar: false };

	case SHOW_DESKTOP_SIDEBAR:
		return { ...state, sidebarDesktop: true } ;
	case HIDE_DESKTOP_SIDEBAR:
		return { ...state, sidebarDesktop: false };

	
	  
	default:
		return state;
	}
  };
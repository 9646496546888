import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../UI/Button";
import { Form, Input } from "antd";
import { ReactComponent as Tooltip } from "../../../assets/tooltip.svg";

import { connect } from "react-redux";
import { login } from "../../../store/actions/auth";
import { getUserInfo } from "../../../store/actions/userInfo";

const ContentSignIn = ({ dispatch }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [error, setError] = useState("Обов`язкове поле");
  const [submitError, setSubmitError] = useState({
    state: false,
    text: "Логін або пароль невірні",
  });

  const handlerSubmit = (e) => {
    setButtonDisable(true);
    form
      .validateFields()
      .then((values) => {
        dispatch(login(values))
          .then(() => {
            dispatch(getUserInfo()).then((response) => {
              if (response && response.active) {
                navigate("/general");
              } else {
                navigate("/verification");
              }
              setSubmitError({ ...submitError, state: false });
            });
          })
          .catch((error) => {
            setButtonDisable(false);
            setSubmitError({ ...submitError, state: true });
          });
      })
      .catch((error) => {});
  };

  return (
    <div className="registration__center">
      <div className="registration__block--form">
        <div className="form">
          <div className="form__login-title">
            Вхід до <br />
            особистого кабінету
          </div>
          <Form form={form} name="sign-in" onFinish={handlerSubmit}>
            <Form.Item
              className={`form__field-signin ${
                submitError.state && "ant-form-item-has-error"
              }`}
              name="identifier"
              rules={[{ required: true, message: error }]}
            >
              <Input
                type="email"
                placeholder="Введіть еmail"
                pattern="^([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})$"
                suffix={
                  <>
                    <span className="tooltip">
                      <Tooltip />
                    </span>
                    <div className="tooltip__text">
                      Вкажіть вашу email-адресу, за якою був зареєстрований
                      профіль вашої компанії
                    </div>
                  </>
                }
              />
            </Form.Item>
            <Form.Item
              className={`form__field-signin ${
                submitError.state && "ant-form-item-has-error"
              }`}
              name="password"
              rules={[{ required: true, message: error }]}
            >
              <Input.Password placeholder="Введіть пароль" />
            </Form.Item>
            {submitError.state && (
              <div className="ant-form-item-error-explain">
                {submitError.text}
              </div>
            )}
            <div className="form__link-recovery">
              <Link
                to="/forgot-password"
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              >
                <div className="button__remind"> Забули пароль?</div>
              </Link>
            </div>

            <Button
              disabled={buttonDisable}
              text="Увійти"
              type="submit"
              className="button button__submit"
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;
  return {
    userInfo,
  };
}

ContentSignIn.propTypes = {};

export default connect(mapStateToProps)(ContentSignIn);

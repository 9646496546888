import { combineReducers } from "redux";
import auth from "./auth";
import sidebar from "./sidebar";
import header from "./header";
import userInfo from "./userInfo";
import filters from "./filters";
import calculation from "./calculation";



export default combineReducers({
  auth,
  sidebar,
  header,
  userInfo,
  filters,
  calculation,
});
import axios from "axios";
import {requestsUrl} from '../../constants/contants';




class AuthService {

  register({firstName, middleName, lastName, companyName, email, phone, password, username}) {
    return axios.post(requestsUrl.url + requestsUrl['type'].auth + '/local/register', {
      firstName, 
      middleName, 
      lastName, 
      companyName,  
      email, phone,
      password, username
    })
	.then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
        return response;
      })
  .catch((error) => {
    return Promise.reject({
      ...error
    });
  })
  }


  login({identifier, password}) {
    return axios.post(requestsUrl.url + requestsUrl['type'].auth + '/local', { identifier, password })
      .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
        return response;
      })
      .catch((error) => {
          console.error('Ошибка о невалидном логине или пароле')
          
      })
  }

  forgotPassword({email}) {
    return axios.post(requestsUrl.url + requestsUrl['type'].auth + '/forgot-password', { email })
	.then((response) => {
   
    return response;
    })
    .catch((error) => {
        
    })
  }

  resetPassword({password, passwordConfirmation, code}) {
    return axios.post(requestsUrl.url + requestsUrl['type'].auth + '/reset-password', {password, passwordConfirmation, code})
	.then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
    return response;
    })
    .catch((error) => {
       
    })
  }
}

export default new AuthService();
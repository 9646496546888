import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as Dropdown } from "../../assets/dropdown.svg";
import InfoBlocks from "./InfoBlocks";
import ChartConsumption from "./ChartConsumption";
import TableConsumption from "./TableConsumption";
import DatePickerSelectForContract from "./DatePickerForContract";
import SkeletonRect from "./SkeletonRect";
import moment from "moment";
import {
  getConsumptionPerMonthByContract,
  getConsumptionPerDayByContract,
} from "../../store/actions/userInfo";
import TariffPlanModal from "../../components/modals/TariffPlanModal";
import ModalLayout from "../../template/ModalLayout";

import { askoePointsByContract } from "../../store/actions/userInfo";

const monthRange = [
  "січ",
  "лют",
  "бер",
  "квіт",
  "трав",
  "черв",
  "лип",
  "серп",
  "вер",
  "жовт",
  "лист",
  "груд",
];

const ContractCard = ({ contract, dispatch, indexContract, groupB, items }) => {
  const [dropdownState, setDropdownState] = useState(
    indexContract === 0 ? true : false
  );
  const [controlSection, setControlSection] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [askoePointsAmount, setAskoePointsAmount] = useState(null);
  const [firstDay, setFirstDay] = useState(null);
  const [lastDay, setLastDay] = useState(null);

  const [consumptionFact, setConsumptionFact] = useState(null);
  const [daysRange, setDaysRange] = useState(null);

  useEffect(() => {
    dispatch(askoePointsByContract(contract.id)).then((response) =>
      setAskoePointsAmount(response.data.length)
    );
  }, []);

  useEffect(() => {
    if (groupB) {
      return (
        setFirstDay(moment().startOf("year").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("year").format("YYYY-MM-DD"))
      );
    }

    if (!groupB) {
      return (
        setFirstDay(moment().startOf("month").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("month").format("YYYY-MM-DD"))
      );
    }
  }, [groupB]);

  const openDropdown = useCallback(() => {
    dropdownState ? setDropdownState(false) : setDropdownState(true);
  }, []);

  useEffect(() => {
    if (lastDay) {
      if (groupB) {
        dispatch(
          getConsumptionPerMonthByContract(firstDay, lastDay, contract.id)
        ).then((res) => {
          const { amount } = res;
          setConsumptionFact(amount);
        });
        return;
      }

      if (!groupB) {
        dispatch(
          getConsumptionPerDayByContract(firstDay, lastDay, contract.id)
        ).then((res) => {
          const { amount } = res;
          setConsumptionFact(amount);
        });

        let computedDates = [];
        let startingMoment = moment(firstDay);
        while (startingMoment <= moment(lastDay)) {
          computedDates.push(startingMoment.format("DD.MM"));
          startingMoment.add(1, "days");
        }

        setDaysRange(computedDates);
      }
    }
  }, [lastDay, groupB]);

  const pointsCountForGroupB = contract.pointsCount ?? 0;

  if (groupB === null) {
    return <SkeletonRect />;
  }
  return (
    <>
      <div className="contract-card">
        <div className="contract-card__wrap">
          <div className="contract-card__header">
            <div className="contract-card__header-left">
              <div className="contract-card__title">
                Договір № {contract.contractNumber}
              </div>
              <div className="contract-card__date">
                Від {moment(contract.dateIn).format(`DD.MM.YY`)} р.
              </div>
              <div className="contract-card__connected-point">
                {groupB ? pointsCountForGroupB : askoePointsAmount} точки(-ок)
                підключення
              </div>
            </div>

            {contract.contractTariff && (
              <div className="contract-card__header-right">
                <div className="contract-card__tariff">
                  Умови оплати згідно договору: {contract.contractTariff}
                </div>
              </div>
            )}
          </div>
          <div className="separator"></div>

          {dropdownState && (
            <>
              <div className="contract-card__info">
                <div className="contract-card__info-top">
                  <div className="contract-card__info-title">
                    Інформація за договором
                  </div>
                </div>
                <InfoBlocks
                  blocks={[
                    {
                      title: "Загальний обсяг спожитої електроенергії",
                      сontent:
                        (Math.round(contract.contractVolume * 100) / 100)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                          " " +
                          "  кВт*год" || "",
                    },
                    {
                      title: "Стан балансу на рахунку",
                      price: contract.saldo,
                    },
                  ]}
                />
                <div className="separator"></div>
                <div className="general-info__consumption">
                  <div className="general-info__consumption-title">
                    Історія споживання електроенергії
                  </div>
                  <div className="general-info__consumption-switch">
                    {groupB ? (
                      <DatePickerSelectForContract
                        items={items}
                        switchState={true}
                        placeholder="Рік"
                        setFirstDay={setFirstDay}
                        setLastDay={setLastDay}
                        firstDay={firstDay}
                        lastDay={lastDay}
                      />
                    ) : (
                      <DatePickerSelectForContract
                        datepicker
                        switchState={false}
                        placeholder="Період"
                        setEventsSelect={"auto"}
                        noDisabled={true}
                        setFirstDay={setFirstDay}
                        setLastDay={setLastDay}
                        firstDay={firstDay}
                        lastDay={lastDay}
                      />
                    )}
                  </div>
                </div>
                {controlSection ? (
                  <ChartConsumption
                    className="chart__main chart__main-consumption"
                    consumption={consumptionFact}
                    xAvis={groupB ? monthRange : daysRange}
                    button="Завантажити аналітичні матеріали в Exсel "
                    title={`Споживання за договором  ${contract.contractNumber}`}
                  />
                ) : (
                  <div className="general-info__table">
                    <TableConsumption button="Завантажити аналітичні матеріали в Exсel " />
                  </div>
                )}
              </div>
            </>
          )}
          <div className="contract-card__show-more" onClick={openDropdown}>
            {dropdownState ? "Показати менше" : "Показати більше"}
            <Dropdown
              className={
                dropdownState
                  ? "contract-card__show-more--open"
                  : "contract-card__show-more--close"
              }
            />
          </div>
        </div>
      </div>

      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          className="background"
          children={<TariffPlanModal setIsModalVisible={setIsModalVisible} />}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { items } = state.userInfo;

  return {
    items,
  };
}

export default connect(mapStateToProps)(ContractCard);

import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Button from '../../UI/Button';
import {ReactComponent as Tooltip} from "../../assets/tooltip.svg";

import "swiper/css";
import "swiper/css/pagination";





const TariffPlanModal = ({setIsModalVisible}) => {
	const [modalTariffSuccess, setModalTariffSuccess] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);
	
	

	const handleModalSuccess = () => {
		setModalTariffSuccess(false)
	};

	

	useEffect(() => {
	  function handleResize() {
		setWidth(window.innerWidth);
	  }
	  window.addEventListener("resize", handleResize);
	  return () => window.removeEventListener("resize", handleResize);

	  
	}, [width]);

	// useEffect(() => {
	// 	let swiper = new Swiper('.swiper-container', {
	// 		loop: true,
	// 		sliderPerview: 1,
	// 		centeredSlider: true,
	// 		pagination: {
	// 			el: '.swiper-pagination',
	// 		}
	// 	})
	// 	setSwiper(swiper)		
	//   }, []);
  


	const headings = ["Електроенергія", "Електроенергія + розподіл"]
	const rows = [
		{data: 'Ціна за кВт', 
		 text:'Змінюється щодня',
		 text2:'7,99 грн щомісяця'},

		 {data: 'Коли можна підключити', 
		 text:'У будь-який момент',
		 text2:'У будь-який момент'},

		 {data: 'Термін дії', 
		 text:'Необмежений',
		 text2:'Змінюється щодня'},

		 {data: 'Умови підключення', 
		 text:'Без обмежень',
		 text2:'Без обмежень'},
	]


	return (
		<div className="tariff-plan">
			{modalTariffSuccess && 
				<div className="tariff-plan__plan">
					<div className="tariff-plan__wrap">
			 			<div className="modal__close" onClick={()=> setIsModalVisible(false)} ></div>
			 			<div className="tariff-plan__title">Тарифні плани</div>
			 			<div className="tariff-plan__сontent">

						 {width > 768 ?
							 <>
								<div className="tariff-plan__block">
									
									<div className="tariff-plan__block-title">Ціна за кВт</div>
									<div className="tariff-plan__block-title">Коли можна підключити</div>
									<div className="tariff-plan__block-title">Термін дії</div>
									<div className="tariff-plan__block-title">Умови підключення</div>
								</div>
						
								<div className="tariff-plan__block">
									<div className="tariff-plan__block-heading">Електроенергія</div>
									
									<div className="tariff-plan__block-item">
										<div className="tariff-plan__block-title-tb">Ціна за кВт</div>
										Змінюється щодня
									</div>
									<div className="tariff-plan__block-item">
										<div className="tariff-plan__block-title-tb">Коли можна підключити</div>
										У будь-який момент
									</div>
									<div className="tariff-plan__block-item">
										<div className="tariff-plan__block-title-tb">Термін дії</div>
										Необмежений
									</div>
									<div className="tariff-plan__block-item">
										<div className="tariff-plan__block-title-tb">Умови підключення</div>
										Без обмежень
									</div>
									<Button text='Залишити заявку' className='button button__full' sendRequest={handleModalSuccess}></Button>
								</div>
						
								<div className="tariff-plan__block">
									<div className="tariff-plan__block-heading">Електроенергія + розподіл 
										<span className="tooltip"><Tooltip/></span>	
										<span className="tooltip__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis hic, accusamus fugit asperiores esse, magni consectetur nostrum totam!</span>
									</div>
									<div className="tariff-plan__block-item">	
											<div className="tariff-plan__block-title-tb">Ціна за кВт</div>
											7,99 грн щомісяця 
											<span>фіксована протягом року</span>
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Коли можна підключити</div>
											У будь-який момент
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Термін дії</div>
											Змінюється щодня
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Умови підключення</div>
											Без обмежень
										</div>
									<Button text='Активний тариф' className='button button__active-tariff' ></Button>
								</div>
						 	</>
							:
						 	<Swiper pagination={true} modules={[Pagination]} className="mySwiper"> 
								<SwiperSlide>
									<div className="tariff-plan__block">
										<div className="tariff-plan__block-heading">Електроенергія</div>
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Ціна за кВт</div>
											Змінюється щодня
										</div>
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Коли можна підключити</div>
											У будь-який момент
										</div>
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Термін дії</div>
											Необмежений
										</div>
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Умови підключення</div>
											Без обмежень
										</div>
										<Button text='Залишити заявку' className='button button__full' sendRequest={handleModalSuccess}></Button>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="tariff-plan__block">
										<div className="tariff-plan__block-heading">Електроенергія + розподіл 
											<span className="tooltip"><Tooltip/></span>	
											<span className="tooltip__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis hic, accusamus fugit asperiores esse, magni consectetur nostrum totam!</span>
										</div>
										<div className="tariff-plan__block-item">	
											<div className="tariff-plan__block-title-tb">Ціна за кВт</div>
											7,99 грн щомісяця 
											<span>фіксована протягом року</span>
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Коли можна підключити</div>
											У будь-який момент
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Термін дії</div>
											Змінюється щодня
										</div>
										
										<div className="tariff-plan__block-item">
											<div className="tariff-plan__block-title-tb">Умови підключення</div>
											Без обмежень
										</div>
										<Button text='Активний тариф' className='button button__active-tariff' ></Button>
									</div>
								</SwiperSlide>
							</Swiper>
							
							
						}	
						 </div>
					</div>
				</div>
			}

			{!modalTariffSuccess &&
				<div className="tariff-plan__sent">
					<div className="tariff-plan__sent-wrap">
					<div className="modal__close" onClick={()=> setIsModalVisible(false)} ></div>
						<div className="tariff-plan__sent-content">
							<div className="tariff-plan__sent-title">Заявка надіслана</div>
							<div className="tariff-plan__sent-text">Ми прийняли вашу заявку на зміну тарифу й розглянемо її в робочий час протягом 24 годин. Інформацію про погодження тарифу ви побачите в особистому кабінеті. </div>
							<div className="tariff-plan__sent-text">Нарахування за новим тарифом розпочнутьсяз наступного місяця.</div>
						</div>
						<Button text='Зрозуміло'  className=" button button__submit" sendRequest={()=> setIsModalVisible(false)}/>
					</div>
				</div>
				}
		</div>
	)
  };
  

  
  export default TariffPlanModal;
import axios from "axios";
import { requestsUrl } from "../../constants/contants";
import authHeader from "./auth.header";
import { LOGOUT_USER } from "../actions/types";

class UserInfoService {
  async getUserInfo() {
    try {
      const response = await axios.get(
        requestsUrl.url + `/api/users/me?populate=deep,4`,
        {
          headers: authHeader(),
        }
      );

      const groupB =
        response?.data?.company?.contracts?.some(
          (contract) => contract.groupB === true
        ) ?? false;

      localStorage.setItem("groupB", JSON.stringify(groupB));
      localStorage.setItem("userInfo", JSON.stringify(response.data));

      return { data: response.data, groupB: groupB };
    } catch (error) {
      localStorage.removeItem("user");
      localStorage.removeItem("userInfo");

      if (typeof dispatch === "function") {
        dispatch({
          type: LOGOUT_USER,
        });
      }

      throw error;
    }
  }

  updateUserInfo(payload, id) {
    return axios
      .put(requestsUrl.url + `/api/users/${id}`, payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getInvoicesInfo(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/invoices?filters[contract][company][id][$eq]=${id}&populate=*`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getPaymentsInfo(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/payments?filters[contract][company][id][$eq]=${id}&populate=*`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getDocumentsInfo(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/documents?filters[contract][company][id]=${id}&populate=deep,2`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }
  //remove
  getContractInfo() {
    return axios
      .get(requestsUrl.url + `/api/users/me?populate=deep,3`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  askoePointsInfo(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/metering-points?filters[contract][company][id][$eq]=${id}&filters[parentPoint][id][$null]=true&populate=deep,3`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  askoePointsByContract(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/metering-points?filters[contract][id][$eq]=${id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  postRequestAct(items) {
    return axios
      .post(requestsUrl.url + `/api/requests`, items, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getDiscountInfo(id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/discounts?filters[meteringPoint][contract][company][id]=${id}&populate=deep,3`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getPricesInfo() {
    return axios
      .get(requestsUrl.url + `/api/prices?populate=deep,3`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getPricesRange(firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/prices?populate=deep,2&filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }
  //remove
  getConsumptionByCompany(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumptions?filters[accessPoint][contract][company][id][$eq]=${id}&populate=deep,2&filters[accessPoint][parentPoint][id][$null]=true&filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionByContract(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumptions?filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}&filters[accessPoint][contract][id][$eq]=${id}&populate=deep,3&filters[accessPoint][parentPoint][id][$null]=true`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionByPoint(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumptions?filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}&filters[accessPoint][id][$eq]=${id}&populate=deep,2`,
        { headers: authHeader() }
      )

      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionOrderByCompany(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumption-orders?filters[company][id][$eq]=${id}&filters[month][$gte]=${firstDay}&filters[month][$lte]=${lastDay}&populate=deep,3`,
        { headers: authHeader() }
      )

      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionOrderByContract(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumption-orders?filters[contract][id][$eq]=${id}&filters[month][$gte]=${firstDay}&filters[month][$lte]=${lastDay}&populate=deep,3`,
        { headers: authHeader() }
      )

      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionOrderByPoint(id, firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/consumption-orders?filters[accessPoint][id][$eq]=${id}&filters[month][$gte]=${firstDay}&filters[month][$lte]=${lastDay}&populate=deep,3`,
        { headers: authHeader() }
      )

      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  updateConsumptionOrder(items, id) {
    return axios
      .put(requestsUrl.url + `/api/consumption-orders/${id}`, items, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  createConsumptionOrder(items) {
    return axios
      .post(requestsUrl.url + `/api/consumption-orders`, items, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionPerDay(firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/day-consumptions?filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionPerMonth(firstDay, lastDay) {
    return axios
      .get(
        requestsUrl.url +
          `/api/month-consumptions?filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionPerDayByContract(firstDay, lastDay, id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/day-consumptions?populate=deep,2&filters[contract][id][$eq]=${id}&filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getConsumptionPerMonthByContract(firstDay, lastDay, id) {
    return axios
      .get(
        requestsUrl.url +
          `/api/month-consumptions?populate=deep,2&filters[contract][id][$eq]=${id}&filters[date][$gte]=${firstDay}&filters[date][$lte]=${lastDay}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  getGeneralConsumptionPerMonth() {
    return axios
      .get(requestsUrl.url + `/api/month-consumptions`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }
}

export default new UserInfoService();

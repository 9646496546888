import React, { useMemo } from "react";
import MainLayout from "../../template/MainLayout";
import TabBlock from "../../components/blocks/Tab";
import GeneralInfoAllContracts from "./GeneralInfoAllContracts";
import GeneralInfoSeparateContracts from "./GeneralInfoSeparateContracts";
import GeneralInfoDiscount from "./GeneralInfoDiscount";
import { connect } from "react-redux";

const GeneralInfo = ({ userInfo }) => {
  const DiscountTab = useMemo(() => {
    const isDiscount = userInfo.company?.discountTab ?? null;

    if (isDiscount) {
      return {
        tab: "Дисконт",
        сontent: <GeneralInfoDiscount userInfo={userInfo} />,
      };
    }
    return { tab: "", content: null };
  }, [userInfo]);

  return (
    <>
      <MainLayout
        title="Дані по споживанню"
        text="Тут міститься детальна інформація за рахунками та аналітика споживання за всіма договорами"
      >
        <TabBlock
          tabs={[
            {
              tab: "Загальна інформація",
              сontent: <GeneralInfoAllContracts />,
            },
            {
              tab: "Договори",
              сontent: <GeneralInfoSeparateContracts />,
            },
            DiscountTab,
          ]}
        ></TabBlock>
      </MainLayout>
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(GeneralInfo);

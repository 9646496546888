import React, { useEffect } from "react";
import entrance from "../../../assets/entrance.svg";
import Button from "../../../UI/Button";
import Icon from "../../../UI/Icon";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { ReactComponent as Svg } from "../../../assets/arrow-next.svg";
import { connect } from "react-redux";
import { NONAUTH_HEADER } from "../../../store/actions/types";

const ContentStep = ({ userInfo, user, dispatch }) => {
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: NONAUTH_HEADER });
    if (userInfo && userInfo.active) {
      navigate("/general");
    } else if (userInfo && !userInfo.active) {
      navigate("/verification");
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="registration__right">
      <div className="registration__block">
        <Icon img={entrance} className="icon" />
        <div className="registration__title-wrap">
          <div className="registration__title registration__title--bold">
            Увійдіть або відправте запит на укладання договору,{" "}
          </div>
          <div className="registration__title registration__title--light">
            щоб користуватись кабінетом KNESS Energy
          </div>
        </div>
        <div className="registration__separator"></div>
        <div className="registration__button">
          <Link to="/sign-in">
            <Button text="Увійти" className="button button__full" />
          </Link>
          <Link to="/sign-up" className="registration__link">
            Зареєструватися / Надіслати запит на укладання договору
            <span>
              <Svg className="registration__link-arrow" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo,
    { user } = state.auth;

  return {
    userInfo,
    user,
  };
}

export default connect(mapStateToProps)(ContentStep);

import React from "react";
import PropTypes from "prop-types";
import Button from "../../UI/Button";

const InfoBlocks = ({ blocks, sendRequestAct }) => {
  return (
    <div className="info-block">
      <div className="info-block__wrap">
        <div className="info-block__items">
          {blocks.map((blocks, index) => (
            <div className="info-block__item" key={index}>
              <div className="info-block__item-wrap">
                <div className="info-block__title">{blocks.title}</div>

                {blocks.price || blocks.price == 0 ? (
                  <div className="info-block__balance">
                    {blocks.price > 0 ? (
                      <div className="info-block__balance-debt">
                        <div className="info-block__balance-state">
                          борг складає
                        </div>
                        <div className="info-block__balance-price">
                          -
                          {blocks.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                          грн
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          blocks.price == 0
                            ? "info-block__balance-equally"
                            : "info-block__balance-overpayment"
                        }
                      >
                        <div className="info-block__balance-state">
                          {blocks.price == 0 ? `складає` : `переплата складає`}
                        </div>
                        <div className="info-block__balance-price">
                          {blocks.price == 0
                            ? blocks.price
                            : `${blocks.price
                                .toString()
                                .replace("-", "+")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}{" "}
                          грн{" "}
                        </div>
                      </div>
                    )}
                    {blocks.button ? (
                      <Button
                        sendRequest={sendRequestAct}
                        text={blocks.button}
                        className="button button__submit"
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="info-block__сontent">{blocks.сontent}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

InfoBlocks.propTypes = {
  blocks: PropTypes.array,
};
export default InfoBlocks;

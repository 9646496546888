import React from 'react';
import ContentForgotPassword from './ContentForgotPassword';
import RegistrationLayout from "../../../template/RegistrationLayout";
import SidebarLayout from '../../../template/SidebarLayout';




const ForgotPassword = () => {

	return (
		<RegistrationLayout link='/sign-in'>
				<div className="registration__main">
					<SidebarLayout link='/'/>
            		<ContentForgotPassword/>
				</div>
		</RegistrationLayout>
				


		
	)
  }

  export default ForgotPassword;
import React, { useState, useEffect } from "react";
import MainLayout from "../../template/MainLayout";
import TabBlock from "../../components/blocks/Tab";
import OrderEnergyGeneral from "./OrderEnergyGeneral";
import { connect } from "react-redux";
import { getPricesRange } from "../../store/actions/userInfo";
import { getFilters } from "../../store/actions/filters";
import moment from "moment";
import FilterBlockTablet from "../../components/blocks/FilterBlockTablet";
import ModalLayout from "../../template/ModalLayout";

const OrderEnergy = ({ userInfo, filteredItems, filters, dispatch }) => {
  const [contract, setContract] = useState(null);
  const [selectOption, setSelectOption] = useState(null);
  const [searchContract, setSearchContract] = useState(null);
  const [averagePriceDay, setAveragePriceDay] = useState(null);
  const today = moment().format("YYYY-MM-DD");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (userInfo?.company) {
      const options = userInfo.company.contracts.filter(
        (item, index, array) =>
          index ===
          array.findIndex((t) => t.contractNumber === item.contractNumber)
      );
      setSelectOption(options);
    }

    setSearchContract(null);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.company) {
      dispatch(getPricesRange(today, today)).then((response) => {
        const averagePricePerDay = response.data
          .map((price) => ({
            date: price.date,
            price: price.hourlyPrice
              .map((item) => item.price)
              .reduce((a, b) => a + b),
          }))
          .map((item) => ({
            date: item.date,
            price: (item.price * userInfo.company.coefficient) / 24 / 1000,
          }));
        setAveragePriceDay(averagePricePerDay);
      });
    }
  }, []);

  useEffect(() => {
    if (filteredItems) {
      const groupByContract = _.values(
        _.groupBy(filteredItems, "contract.contractNumber")
      );
      setContract(groupByContract);
    }
  }, [filteredItems]);

  useEffect(() => {
    if (searchContract) {
      dispatch(getFilters({ ...filters, searchContract }));
    }
  }, [searchContract]);

  return (
    <>
      <MainLayout
        title="Замовлення електроенергії"
        text="Ви можете замовити електроенергію або скоригувати її обсяг залежно від обраного місяця  для кожної точки окремо."
        className="full"
      >
        <TabBlock
          tabs={[
            {
              tab: "Загально",
              сontent: (
                <OrderEnergyGeneral
                  options={selectOption}
                  setSearchContract={setSearchContract}
                  searchContract={searchContract}
                  averagePriceDay={averagePriceDay}
                  setIsModalVisible={setIsModalVisible}
                />
              ),
            },
          ]}
          className="react-tabs__tab hidden"
        ></TabBlock>
      </MainLayout>
      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          children={
            <FilterBlockTablet setIsModalVisible={setIsModalVisible} contract />
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo,
    { filteredItems } = state.userInfo,
    { filters } = state.filters,
    { items } = state.userInfo;

  return {
    userInfo,
    filteredItems,
    filters,
    items,
  };
}

export default connect(mapStateToProps)(OrderEnergy);

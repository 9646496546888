import React, { useState, useEffect } from "react";
import Button from "../../../UI/Button";
import { Form, Input } from "antd";
import { ReactComponent as TooltipInfo } from "../../../assets/tooltipInfo.svg";
import ModalLayout from "../../../template/ModalLayout";
import InformModal from "../../../components/modals/InformModal";

import { connect } from "react-redux";
import { forgotPassword } from "../../../store/actions/auth";

const ContentForgotPassword = (props) => {
  const [form] = Form.useForm();
  const { dispatch } = props;
  const [error, setError] = useState("Обов`язкове поле");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setButtonDisable(false);
  }, [buttonDisable]);

  const handlerSubmit = (e) => {
    setButtonDisable(true);
    form
      .validateFields()
      .then((values) => {
        dispatch(forgotPassword(values)).then(() => {
          setIsModalVisible(true);
        });
      })

      .catch((error) => {
        setButtonDisable(false);
      });
  };

  return (
    <div className="registration__forgot-pass">
      <div className="registration__block--form">
        <div className="form">
          <div className="form__login-title">
            Відновлення <br />
            пароля
          </div>
          <Form form={form} name="forgot-password" onFinish={handlerSubmit}>
            <Form.Item
              className="form__field-signin"
              name="email"
              rules={[{ required: true, message: error }]}
            >
              <Input
                type="email"
                placeholder="Введіть email"
                pattern="^([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})$"
                suffix={
                  <>
                    <span className="tooltip tooltip--info">
                      <TooltipInfo />
                    </span>
                    <div className="tooltip__text">
                      Вкажіть вашу email-адресу, за якою був зареєстрований
                      профіль вашої компанії
                    </div>
                  </>
                }
              />
            </Form.Item>
            <Button
              disabled={buttonDisable}
              text="Надіслати пароль"
              type="submit"
              className="button  button__submit"
            />
          </Form>
        </div>

        {isModalVisible && (
          <ModalLayout
            isModalVisible={isModalVisible}
            className="background"
            children={
              <InformModal
                setIsModalRequestVisible={setIsModalVisible}
                title="Запит надіслано"
                text="Якщо ваш email є в системі, то на нього буде відправлена інформація по відновленню пароля"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default connect()(ContentForgotPassword);

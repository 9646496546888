import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  AUTH_HEADER,
  NONAUTH_HEADER,
  LOGOUT_USER,
} from "./types";
import AuthService from "../services/auth.service";

export const register =
  ({
    firstName,
    middleName,
    lastName,
    companyName,
    email,
    phone,
    password,
    username,
  }) =>
  (dispatch) => {
    return AuthService.register({
      firstName,
      middleName,
      lastName,
      companyName,
      email,
      phone,
      password,
      username,
    }).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: { user: response },
        });

        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: REGISTER_FAIL,
        });

        return Promise.reject();
      }
    );
  };

export const login =
  ({ identifier, password }) =>
  (dispatch) => {
    return AuthService.login({ identifier, password }).then(
      (response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response },
        });
        dispatch({
          type: AUTH_HEADER,
        });
        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: LOGIN_FAIL,
        });
        return Promise.reject();
      }
    );
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("groupB");
  dispatch({
    type: LOGOUT,
  }),
    dispatch({
      type: LOGOUT_USER,
    });
  return Promise.resolve();
};

export const forgotPassword =
  ({ email }) =>
  (dispatch) => {
    return AuthService.forgotPassword({ email }).then(
      (response) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        });
        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
        });
        return Promise.reject();
      }
    );
  };

export const resetPassword =
  ({ password, passwordConfirmation, code }) =>
  (dispatch) => {
    return AuthService.resetPassword({
      password,
      passwordConfirmation,
      code,
    }).then(
      (response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response },
        });
        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
    );
  };

import React, { useState, useEffect, useMemo, useRef } from "react";
import Icon from "../../UI/Icon";
import dropdown from "../../assets/dropdown.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import uk from "date-fns/locale/uk";
registerLocale("uk", uk);

const DatePickerSelectForContract = ({
  items,
  datepicker,
  switchState,
  placeholder,
  noDisabled = false,
  setFirstDay,
  setLastDay,
  firstDay,
  lastDay,
}) => {
  const [yearDate, setYearDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(switchState);
  const [filterDate, setFilterDate] = useState(false);
  const dateStartRange = moment(firstDay).format("DD.MM.YY");
  const dateEndRange = moment(lastDay).format("DD.MM.YY");

  const currentYear = moment().format("YYYY");
  const yearsList = [];
  const ref = useRef();
  const refYear = useRef();

  const itemsYears = useMemo(() => {
    return [
      ...new Set(
        items &&
          _.sortBy(items, "date").map((item) => {
            return moment(item.date).format("YYYY");
          })
      ),
    ];
  }, [items]);

  if (itemsYears) {
    for (let i = +itemsYears?.[0]; i <= currentYear; i++) {
      yearsList.push(i.toString());
    }
  }

  const onChangePeriod = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const onChangeYear = (e) => {
    setFirstDay(
      moment(e.target.innerHTML).startOf("year").format("YYYY-MM-DD")
    );
    setLastDay(moment(e.target.innerHTML).endOf("year").format("YYYY-MM-DD"));
    setYearDate(e.target.innerHTML);
  };

  const openFilterDate = (e) => {
    filterDate ? setFilterDate(false) : setFilterDate(true);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setFirstDay(moment(startDate).format("YYYY-MM-DD"));
      setLastDay(moment(endDate).format("YYYY-MM-DD"));
    }

    if (yearDate || (startDate && endDate)) {
      setFilterDate(false);
    }
  }, [yearDate, endDate]);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterDate(false);
      }
    };

    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  useEffect(() => {
    const target = document.querySelector(`[data-type="active"]`);

    if (target) {
      target.parentNode.scrollTop = target.offsetTop - 28;
    }
  }, [filterDate]);

  return (
    <>
      <div className="datepicker" ref={ref}>
        <div
          className={
            items?.length > 0 || noDisabled
              ? "datepicker__select"
              : "datepicker__select datepicker--disabled"
          }
          onClick={openFilterDate}
        >
          <input
            type="text"
            className={
              selectedMonth
                ? "datepicker__input datepicker__input--year"
                : "datepicker__input "
            }
            placeholder={placeholder}
            defaultValue={
              (!selectedMonth && startDate !== null
                ? `${dateStartRange} - ${endDate !== null ? dateEndRange : ""}`
                : "") ||
              (selectedMonth && yearDate !== null ? `${yearDate}` : "")
            }
          />
          {placeholder === "Період" ? (
            <Calendar />
          ) : (
            <Icon
              img={dropdown}
              className={
                filterDate
                  ? "datepicker__select-arrow--open"
                  : "datepicker__select-arrow"
              }
            />
          )}
        </div>
        {filterDate && (
          <div className="datepicker__wrap">
            {datepicker && switchState && (
              <div className="datepicker__switch">По рокам</div>
            )}

            {!selectedMonth && (
              <div className="datepicker-calendar">
                <DatePicker
                  selected={startDate}
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChangePeriod}
                  calendarStartDay={1}
                  locale="uk"
                  inline
                />
              </div>
            )}

            {selectedMonth && (
              <div className="datepicker-year">
                <ul className="datepicker-year__list" ref={refYear}>
                  {yearsList?.map((year, i) => (
                    <li
                      key={year}
                      className="datepicker-year__item"
                      data-type={
                        year === yearDate || year === moment().format("YYYY")
                          ? "active"
                          : "default"
                      }
                      onClick={onChangeYear}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DatePickerSelectForContract;

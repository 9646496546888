import React from "react";
import SidebarStep from "./SidebarStep";
import ContentStep from "./ContentStep";
import RegistrationLayout from "../../../template/RegistrationLayout";

const RegistrationStep = () => {
  return (
      <RegistrationLayout ellipse>
			    <div className="registration__main">
              <SidebarStep />
              <ContentStep />
          </div>
      </RegistrationLayout>
  )
};

export default RegistrationStep;

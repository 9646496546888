import React, { useState, useEffect, useMemo } from "react";
import InfoBlocks from "../../components/blocks/InfoBlocks";
import ChartConsumption from "../../components/blocks/ChartConsumption";
import TableConsumption from "../../components/blocks/TableConsumption";
import DatePickerSelect from "../../components/blocks/DatePickerSelect";
import SkeletonRect from "../../components/blocks/SkeletonRect";
import { connect } from "react-redux";
import {
  getConsumptionPerMonth,
  getConsumptionPerDay,
  getGeneralConsumptionPerMonth,
} from "../../store/actions/userInfo";
import { cleanFilteredItems } from "../../store/actions/filters";
import moment from "moment";

const GeneralInfoAllContracts = ({
  userInfo,
  dispatch,
  filters,
  groupB,
  items,
}) => {
  const [controlSection, setControlSection] = useState(true);
  const [firstDay, setFirstDay] = useState(null);
  const [lastDay, setLastDay] = useState(null);
  const [daysRange, setDaysRange] = useState([]);
  const monthRange = [
    "січ",
    "лют",
    "бер",
    "квіт",
    "трав",
    "черв",
    "лип",
    "серп",
    "вер",
    "жовт",
    "лист",
    "груд",
  ];

  const [consumptionFact, setConsumptionFact] = useState(null);

  useEffect(() => {
    dispatch(cleanFilteredItems());
  }, []);

  useEffect(() => {
    if (groupB) {
      if (filters?.yearDate) {
        return (
          setFirstDay(
            moment(filters.yearDate).startOf("year").format("YYYY-MM-DD")
          ),
          setLastDay(
            moment(filters.yearDate).endOf("year").format("YYYY-MM-DD")
          )
        );
      }
      return (
        setFirstDay(moment().startOf("year").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("year").format("YYYY-MM-DD"))
      );
    }

    if (!groupB) {
      if (filters?.endDate) {
        return (
          setFirstDay(moment(filters.startDate).format("YYYY-MM-DD")),
          setLastDay(moment(filters.endDate).format("YYYY-MM-DD"))
        );
      }
      return (
        setFirstDay(moment().startOf("month").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("month").format("YYYY-MM-DD"))
      );
    }
  }, [filters, lastDay]);

  useEffect(() => {
    if (lastDay) {
      if (groupB) {
        dispatch(getGeneralConsumptionPerMonth());
        dispatch(getConsumptionPerMonth(firstDay, lastDay)).then((res) => {
          const { amount } = res;
          setConsumptionFact(amount);
        });
      }

      if (!groupB) {
        dispatch(getConsumptionPerDay(firstDay, lastDay)).then((res) => {
          const { amount } = res;
          setConsumptionFact(amount);
        });
      }

      let computedDates = [];
      let startingMoment = moment(firstDay);
      while (startingMoment <= moment(lastDay)) {
        computedDates.push(startingMoment.format("DD.MM"));
        startingMoment.add(1, "days");
      }

      setDaysRange(computedDates);
    }
  }, [lastDay]);

  const contract = useMemo(
    () => userInfo.company?.contracts.length ?? null,
    [userInfo]
  );
  const contractVolume = useMemo(
    () =>
      userInfo.company?.contracts
        .map((item) => item.contractVolume)
        .reduce((a, b) => a + b, 0) ?? 0,
    [userInfo]
  );
  const contractBalance = useMemo(
    () =>
      userInfo.company?.contracts
        .map((item) => item.saldo)
        .reduce((a, b) => a + b, 0) ?? 0,
    [userInfo]
  );

  if (groupB === null) {
    return <SkeletonRect />;
  }

  return (
    <div className="general-info">
      <div className="general-info__wrap">
        <div className="general-info__top">
          <div className="general-info__header">Дані за всіма договорами</div>
        </div>
        <InfoBlocks
          blocks={[
            { title: "Договори", сontent: contract },
            {
              title: "Загальний обсяг спожитої електроенергії",
              сontent:
                (Math.round(contractVolume * 100) / 100)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                  " " +
                  "кВт*год" || "",
            },
            { title: "Стан балансу на рахунку", price: contractBalance },
          ]}
        />

        <div className="general-info__consumption">
          <div className="general-info__consumption-title">
            Історія споживання електроенергії
          </div>
          <div className="general-info__consumption-switch">
            {groupB ? (
              <DatePickerSelect
                items={items}
                switchState={true}
                placeholder="Рік"
              />
            ) : (
              <DatePickerSelect
                datepicker
                switchState={false}
                placeholder="Період"
                setEventsSelect={"auto"}
                noDisabled={true}
              />
            )}
          </div>
        </div>

        {controlSection ? (
          <ChartConsumption
            className="chart__main chart__main-consumption"
            consumption={consumptionFact}
            xAvis={groupB ? monthRange : daysRange}
            groupB={groupB}
          />
        ) : (
          <div className="general-info__table">
            <TableConsumption />
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo, groupB, items } = state.userInfo,
    { filters } = state.filters;

  return {
    userInfo,
    filters,
    groupB,
    items,
  };
}
export default connect(mapStateToProps)(GeneralInfoAllContracts);

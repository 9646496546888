import React, { useEffect, useState } from "react";
import MainLayout from "../../template/MainLayout";
import TabBlock from "../../components/blocks/Tab";
import InfoBlocks from "../../components/blocks/InfoBlocks";
import Invoices from "./Invoices";
import Payments from "./Payments";
import { connect } from "react-redux";
import { getContractInfo, postRequestAct } from "../../store/actions/userInfo";
import RequestAct from "../../components/modals/RequestAct";
import ModalLayout from "../../template/ModalLayout";
import moment from "moment";

const Invoices_Payments = ({ userInfo, dispatch }) => {
  const [saldo, setSaldo] = useState(null);
  const [isModalRequestVisible, setIsModalRequestVisible] = useState(false);

  useEffect(() => {
    if (userInfo?.company) {
      dispatch(getContractInfo()).then((response) => {
        let data = null;

        if (
          response.company.contracts &&
          response.company.contracts.length > 0
        ) {
          data = response.company.contracts.reduce((a, b) => ({
            saldo: a.saldo + b.saldo,
          }));
          setSaldo(Math.round(data.saldo * 100) / 100);
        } else {
          setSaldo(0);
        }
      });
    }
  }, [saldo]);

  const sendRequestAct = () => {
    setIsModalRequestVisible(true);
  };

  return (
    <>
      <MainLayout
        title="Нарахування і платежі"
        text="Даний розділ вказує історію всіх нарахувань та платежі за всіма Договорами контрагенту"
      >
        <div className="payments">
          <div className="payments__info">
            <InfoBlocks
              blocks={[
                {
                  title: "Кінцеве сальдо за договорами:",
                  price: saldo,
                  button: "Сформувати Акт-звірки",
                  tooltip:
                    "Тариф на даний момент. Тариф на даний момент. Тариф на даний момент.",
                },
              ]}
              sendRequestAct={sendRequestAct}
            />
          </div>
          <TabBlock
            tabs={[
              { tab: "Нарахування", сontent: <Invoices /> },
              { tab: "Платежі", сontent: <Payments /> },
            ]}
            className="react-tabs__tab"
          ></TabBlock>
        </div>
      </MainLayout>
      {isModalRequestVisible && (
        <ModalLayout
          isModalVisible={isModalRequestVisible}
          className="background"
          children={
            <RequestAct
              setIsModalVisible={setIsModalRequestVisible}
              title="Залиште коментар"
            />
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(Invoices_Payments);

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { SHOW_DESKTOP_SIDEBAR } from "../../store/actions/types";

import { ReactComponent as Svg1 } from "../../assets/nav1.svg";
import { ReactComponent as Svg2 } from "../../assets/nav2.svg";
import { ReactComponent as Svg3 } from "../../assets/nav3.svg";
import { ReactComponent as Svg4 } from "../../assets/nav4.svg";
import { ReactComponent as Svg5 } from "../../assets/nav5.svg";
import { ReactComponent as Svg7 } from "../../assets/nav7.svg";
import { ReactComponent as Svg8 } from "../../assets/nav8.svg";

const NavSidebarClose = ({ groupB, dispatch }) => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  const closeSidebar = () => {
    dispatch({ type: SHOW_DESKTOP_SIDEBAR });
  };

  return (
    <>
      <div className="sidebar__logo--small">
        <Link to="/" className="sidebar__logo-icon--small"></Link>
      </div>
      <div className="sidebar__separator sidebar__separator--close"></div>
      <ul className="sidebar__list">
        <li
          className={
            splitLocation[1] === "general"
              ? "sidebar__list-item sidebar__list-item--close _active"
              : "sidebar__list-item sidebar__list-item--close"
          }
        >
          <NavLink to="/general" className="sidebar__link ">
            <Svg1 className="icon__nav" />
          </NavLink>
        </li>
        <li
          className={
            splitLocation[1] === "tariff-structure"
              ? "sidebar__list-item sidebar__list-item--close _path _active"
              : "sidebar__list-item sidebar__list-item--close"
          }
        >
          <NavLink to="/tariff-structure" className="sidebar__link ">
            <Svg8 className="icon__nav" />
          </NavLink>
        </li>
        <li
          className={
            splitLocation[1] === "order-energy"
              ? "sidebar__list-item sidebar__list-item--close _active"
              : "sidebar__list-item sidebar__list-item--close"
          }
        >
          <NavLink to="/order-energy" className="sidebar__link ">
            <Svg2 className="icon__nav" />
          </NavLink>
        </li>
        <li
          className={
            splitLocation[1] === "payments"
              ? "sidebar__list-item sidebar__list-item--close _active"
              : "sidebar__list-item sidebar__list-item--close"
          }
        >
          <NavLink to="/payments" className="sidebar__link ">
            <Svg3 className="icon__nav" />
          </NavLink>
        </li>
        <li
          className={
            splitLocation[1] === "documents"
              ? "sidebar__list-item sidebar__list-item--close _active"
              : "sidebar__list-item sidebar__list-item--close"
          }
        >
          <NavLink to="/documents" className="sidebar__link ">
            <Svg4 className="icon__nav" />
          </NavLink>
        </li>
        {!groupB && (
          <li
            className={
              splitLocation[1] === "askoe"
                ? "sidebar__list-item sidebar__list-item--close _active"
                : "sidebar__list-item sidebar__list-item--close"
            }
          >
            <NavLink to="/askoe" className="sidebar__link ">
              <Svg5 className="icon__nav" />
            </NavLink>
          </li>
        )}
      </ul>
      <div className="sidebar__separator sidebar__separator--close"></div>
      <div
        className="sidebar__moderator sidebar__moderator--close"
        onClick={closeSidebar}
      >
        <Svg7 className="icon__nav" />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { groupB } = state.userInfo;

  return {
    groupB,
  };
};

export default connect(mapStateToProps)(NavSidebarClose);

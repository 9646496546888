import React, { useState, useEffect, useCallback } from "react";
import Button from "../../UI/Button";
import { ReactComponent as Tooltip } from "../../assets/tooltip.svg";
import { ReactComponent as Edit } from "../../assets/edit_button.svg";
import { ReactComponent as Add } from "../../assets/add_button.svg";
import ModalLayout from "../../template/ModalLayout";
import OrderModal from "../modals/OrderModal";
import OrderForGroupB from "../modals/OrderForGroupB";
import { connect } from "react-redux";
import moment from "moment";
import { SkeletonRect } from "./SkeletonRect";

const Table = ({
  orderItems,
  averagePriceDay,
  contract,
  askoe,
  setIsModalVisible,
  isModalVisible,
}) => {
  const [modalItems, setModalItems] = useState(null);
  const [tableItems, setTableItems] = useState(null);
  const [tableGroupType, setTableGroupType] = useState("");
  const groupB = contract.groupB ?? false;
  const groupA = contract.groupA ?? true;

  useEffect(() => {
    setTableItems(orderItems);
  }, [orderItems]);

  const showModal = useCallback(
    (month, orderItems, groupType) => () => {
      const modal = orderItems.find((a) => a.month === month);
      setIsModalVisible(true);
      setModalItems(modal);
      setTableGroupType(groupType);
    },
    []
  );

  if (tableItems === null) {
    return <SkeletonRect />;
  }

  return (
    <>
      <table className="table-order">
        <thead className="table-order__thead">
          <tr>
            <th className="table-order__th">Місяць</th>
            <th className="table-order__th">Замовлення, кВт*год</th>
            <th className="table-order__th">
              Прогнозна вартість
              <span className="tooltip">
                <Tooltip />
              </span>
              <span className="tooltip__text">
                Середня вартість за попередній період
              </span>
            </th>
            {groupA && groupB && (
              <>
                <th className="table-order__th">Група А</th>
                <th className="table-order__th">Група Б</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {tableItems?.map((order, i) => {
            const currentDay = moment().format("MM-DD");
            const lastDayOfMonth = moment(order.month)
              .endOf("month")
              .format("MM-DD");
            const isLastDayOfCurrentMonth = currentDay === lastDayOfMonth;
            return (
              <React.Fragment key={`${order}_${i}`}>
                <tr>
                  <th className="table-order__th-body">
                    {moment(order.month)
                      .format("MMMM")
                      .charAt(0)
                      .toUpperCase() +
                      moment(order.month).format("MMMM").slice(1)}
                  </th>
                  <td className="table-order__td ">
                    {order.totalMonthConsumption
                      ? `${order.totalMonthConsumption
                          .toFixed(3)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} кВт*год`
                      : "0 кВт*год"}
                  </td>
                  <td className="table-order__td ">
                    {order.totalMonthConsumption
                      ? `${(
                          order.totalMonthConsumption *
                          (averagePriceDay.length > 0
                            ? averagePriceDay[0].price
                            : 0)
                        )
                          .toFixed(2)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} грн`
                      : "0.00 грн"}
                  </td>
                  {groupA && (
                    <td className="table-order__td ">
                      <Button
                        disabled={isLastDayOfCurrentMonth}
                        text={
                          order.totalMonthConsumption
                            ? "Оновити"
                            : "Надати дані"
                        }
                        className="button button__table"
                        sendRequest={showModal(
                          order.month,
                          orderItems,
                          "groupA"
                        )}
                      />
                      <div
                        className="table-order__button"
                        onClick={showModal(order.month, orderItems, "groupA")}
                      >
                        {order.totalMonthConsumption ? <Edit /> : <Add />}
                      </div>
                    </td>
                  )}
                  {groupB && (
                    <td className="table-order__td ">
                      <Button
                        disabled={isLastDayOfCurrentMonth}
                        text={
                          order.totalMonthConsumption
                            ? "Оновити"
                            : "Надати дані"
                        }
                        className="button button__table"
                        sendRequest={showModal(
                          order.month,
                          orderItems,
                          "groupB"
                        )}
                      />
                      <div
                        className="table-order__button"
                        onClick={showModal(order.month, orderItems, "groupB")}
                      >
                        {order.totalMonthConsumption ? <Edit /> : <Add />}
                      </div>
                    </td>
                  )}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          className="background"
          children={
            tableGroupType === "groupB" ? (
              <OrderForGroupB
                setIsModalVisible={setIsModalVisible}
                order={modalItems}
                contract={contract}
              />
            ) : (
              <OrderModal
                order={modalItems}
                contract={contract}
                askoe={askoe}
                setIsModalVisible={setIsModalVisible}
              />
            )
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo,
    { averagePrice } = state.calculation;

  return {
    userInfo,
    averagePrice,
  };
}

export default connect(mapStateToProps)(Table);

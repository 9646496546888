import React, { useState, useEffect, useMemo } from "react";
import Button from "../../UI/Button";
import TableEditOrder from "../blocks/TableEditOrder";
import InformModal from "../../components/modals/InformModal";
import ModalLayout from "../../template/ModalLayout";
import Loader from "../blocks/Loader";

import moment from "moment";
import { connect } from "react-redux";
import {
  createConsumptionOrder,
  updateConsumptionOrder,
} from "../../store/actions/userInfo";
import { read, utils, writeFile } from "xlsx";

const pattern = /^\d+[.]?\d{0,}$/;

const OrderModal = ({
  userInfo,
  order,
  setIsModalVisible,
  dispatch,
  contract,
  askoe,
}) => {
  const [isFileWrong, setIsFileWrong] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Невірний формат");
  const [errorText, setErrorText] = useState(
    "Перевірте будь ласка введені дані, внесіть корективи та спробуйте завантажити ще раз"
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [headings, setHeadings] = useState(null);
  const setStartHours = moment().set({
    hours: 0,
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const emptyRows = [];
  const hoursRows = [];
  const [createOrder, setCreateOrder] = useState({
    contract: askoe ? askoe.contract.id : contract.id,
    company: userInfo.company.id,
    accessPoint: askoe ? askoe.id : null,
    totalMonthConsumption: null,
    month: moment(order.month).startOf().format("YYYY-MM-DD"),
    monthConsumption: null,
  });

  //check if is data or set rows 0
  for (let i = 0; i < 24; i++) {
    if (order.totalMonthConsumption && !order.accessPoint) {
      hoursRows.push(
        moment(setStartHours).add(i, "hours").format("HH:mm") +
          "-" +
          moment(setStartHours)
            .add(1 + i, "hours")
            .format("HH:mm")
      );
    }
    emptyRows.push({
      time:
        moment(setStartHours).add(i, "hours").format("HH:mm") +
        "-" +
        moment(setStartHours)
          .add(1 + i, "hours")
          .format("HH:mm"),
      consumption: [...Array(moment(order.month).daysInMonth())].map(
        (item) => 0
      ),
    });
  }

  //sort and group if consumption
  const sortedItems = useMemo(() => {
    return _.sortBy(order.monthConsumption, "day");
  }, []);

  const chainedItems = useMemo(() => {
    return sortedItems.map((item) => _.sortBy(item.consumption, "time"));
  }, []);

  const groupedItems = useMemo(() => {
    return chainedItems.flat().reduce((obj, arr, i) => {
      obj[arr.time]
        ? obj[arr.time].consumption.push(arr.consumption)
        : (obj[arr.time] = {
            time: hoursRows[i],
            consumption: [arr.consumption] ?? 0,
          });
      return obj;
    }, {});
  }, []);

  const rows = useMemo(() => {
    return Object.values(groupedItems);
  }, []);

  const [rowsTable, setRowsTable] = useState(
    !order.totalMonthConsumption || order?.monthConsumption?.length < 1
      ? emptyRows
      : rows
  );

  useMemo(() => {
    if (rowsTable) {
      const sum = rowsTable
        ?.map((row) =>
          row?.consumption.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        )
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      setTotal(sum);
    }
  }, [rowsTable]);

  useMemo(() => {
    const newMonthConsumption = [];
    let headings = [];

    for (let i = 1; i <= moment(order.month).daysInMonth(); i++) {
      i < 10 ? (i = "0" + i) : i;
      headings.push(i);
    }

    for (let index = 1; index <= moment(order.month).daysInMonth(); index++) {
      newMonthConsumption.push({
        day: moment(order.month)
          .add(index - 1, "days")
          .format("YYYY-MM-DD"),
        totalDayConsumption: 0,
        consumption: [],
      });
    }

    setCreateOrder({
      ...createOrder,
      monthConsumption: newMonthConsumption,
      totalMonthConsumption: parseFloat(total.toFixed(3)),
    });
    setHeadings(headings);

    newMonthConsumption?.map((item, index) => {
      for (let i = 0; i < 24; i++) {
        item.consumption.push({
          time: moment(setStartHours).add(i, "hours").format("HH:mm:ss.000"),
          consumption: parseFloat(
            rowsTable[i]?.consumption[index].toString().replace(/,/g, ".")
          ),
        });
      }
      if (item.consumption.length > 0) {
        item.totalDayConsumption = item.consumption.reduce((a, b) => {
          return a + b.consumption;
        }, 0);
      }
      return item;
    });
  }, [rowsTable, total]);

  const handlerSubmit = (e) => {
    setIsLoader(true);
    setButtonDisable(true);
    if (order.totalMonthConsumption) {
      dispatch(updateConsumptionOrder({ data: createOrder }, order.id))
        .then(() => {
          setIsLoader(false);
          setIsModalVisible(false);
          setButtonDisable(false);
        })
        .catch((error) => {
          setButtonDisable(false);
        });
    } else {
      dispatch(createConsumptionOrder({ data: createOrder }))
        .then(() => {
          setIsLoader(false);
          setIsModalVisible(false);
          setButtonDisable(false);
        })
        .catch((error) => {
          setButtonDisable(false);
        });
    }
  };

  const handleExport = () => {
    let rowsForExport = JSON.parse(JSON.stringify(rowsTable));
    let xlsHeadings = null;
    let xlsRows = null;

    if (moment(order.month).format("YYYY-MM") === moment().format("YYYY-MM")) {
      xlsRows = rowsForExport.reduce((obj, arr) => {
        obj[arr.time] = [arr.time].concat(
          arr.consumption.splice(moment().date())
        );
        return obj;
      }, {});

      xlsHeadings = [[""].concat(headings.splice(moment().date()))];
    } else {
      xlsRows = Object.values(rowsForExport).reduce((obj, arr) => {
        obj[arr.time] = [arr.time].concat(arr.consumption);
        return obj;
      }, {});

      xlsHeadings = [[""].concat(headings)];
    }

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, xlsHeadings);
    utils.sheet_add_json(ws, Object.values(xlsRows), {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Order");
    writeFile(wb, `Замовлення ${moment(order.month).format("MMMM-YYYY")}.xlsx`);
  };

  const [importFile, setImportFile] = useState(null);

  useEffect(() => {
    if (importFile) {
      const file = importFile[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        let rowsForImport = JSON.parse(JSON.stringify(rowsTable));

        if (sheets.length) {
          let importRows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          let arraysImportRows = null;

          if (importRows[0][""] === "00:00-01:00") {
            moment(order.month).format("YYYY-MM") === moment().format("YYYY-MM")
              ? (arraysImportRows = importRows
                  .map((item) => {
                    return Object.entries(item)
                      .sort((a, b) => a[0] - b[0])
                      .map((el) =>
                        typeof el[1] === "number"
                          ? parseFloat(el[1].toFixed(3))
                          : el[1]
                      );
                  })
                  .map((item, i) => ({
                    time: item.shift(),
                    consumption: rowsForImport[i].consumption
                      .splice(0, moment().date())
                      .concat(item),
                  })))
              : (arraysImportRows = importRows
                  .map((item) => {
                    return Object.entries(item)
                      .sort((a, b) => a[0] - b[0])
                      .map((el) =>
                        typeof el[1] === "number"
                          ? parseFloat(el[1].toFixed(3))
                          : el[1]
                      );
                  })
                  .map((item, i) => ({
                    time: item.shift(),
                    consumption: item,
                  })));

            const isIncorrectData = arraysImportRows
              .map(({ consumption }) => {
                if (
                  consumption.length === moment(order.month).daysInMonth() ||
                  consumption.length ===
                    moment(order.month).daysInMonth() - moment().date()
                ) {
                  return consumption.map((el) => pattern.test(el));
                }
                return false;
              })
              .flat()
              .includes(false);

            if (
              arraysImportRows[0].consumption.length !==
              moment(order.month).daysInMonth()
            ) {
              return (
                setIsFileWrong(true),
                setErrorTitle("Завантажте сьогоднішній шаблон")
              );
            }

            if (isIncorrectData) {
              return setIsFileWrong(true), setErrorTitle("Помилка");
            }

            arraysImportRows[0].consumption.length ===
              moment(order.month).daysInMonth() &&
              setRowsTable(arraysImportRows);
          } else {
            setIsFileWrong(true);
            setErrorTitle("Невірний формат");
          }

          setImportFile(null);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [importFile]);

  return (
    <>
      <div className="order-modal">
        <div className="order-modal__wrap">
          <div className="order-modal__body">
            <div
              className="modal__close"
              onClick={() => setIsModalVisible(false)}
            ></div>
            <div className="order-modal__title">
              {moment(order.month)
                .format("MMMM")
                .charAt(0)
                .toLocaleUpperCase() +
                moment(order.month).format("MMMM").slice(1)}
            </div>
            <div className="order-modal__top-tb">
              <div className="order-modal__sum order-modal__sum--tb">
                Всього: {parseFloat(total.toFixed(3))} кВт*год
              </div>
              <div
                className="order-modal__upload order-modal__upload--tb"
                onClick={handleExport}
              >
                Завантажити шаблон
              </div>
            </div>
            <div className="order-modal__table">
              {rowsTable && (
                <TableEditOrder
                  headings={headings}
                  rows={rowsTable}
                  setRowsTable={setRowsTable}
                  setTotal={setTotal}
                  item={order}
                />
              )}
            </div>

            <div className="order-modal__bottom">
              <div className="order-modal__sum">
                Всього: {parseFloat(total.toFixed(3))} кВт*год
              </div>
              <div className="order-modal__buttons">
                <div className="order-modal__upload" onClick={handleExport}>
                  Завантажити шаблон
                </div>
                <div className="button button__excel">
                  <input
                    type="file"
                    name="file"
                    className="input-file"
                    id="inputGroupFile"
                    required
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => setImportFile(e.target.files)}
                  />
                  <label className="" htmlFor="inputGroupFile">
                    Імпортувати XLS
                  </label>
                </div>
                <Button
                  className="button button__submit"
                  text="Зберегти"
                  type="submit"
                  disabled={buttonDisable}
                  sendRequest={handlerSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFileWrong && (
        <ModalLayout
          isModalVisible={isFileWrong}
          className="background"
          children={
            <InformModal
              setIsModalRequestVisible={setIsFileWrong}
              title={errorTitle}
              text={errorText}
            />
          }
        />
      )}
      {isLoader && (
        <ModalLayout
          isModalVisible={isLoader}
          children={<Loader />}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { items, userInfo } = state.userInfo;
  return {
    items,
    userInfo,
  };
}

export default connect(mapStateToProps)(OrderModal);

import React, { useState, useEffect, useMemo } from "react";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import SelectBlock from "./SelectBlock";
import { connect } from "react-redux";
import { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk";
import FilterBlockTablet from "./FilterBlockTablet";
import ModalLayout from "../../template/ModalLayout";
registerLocale("uk", uk);

import {
  getFilters,
  cleanFilteredItems,
  getFilteredItems,
} from "../../store/actions/filters";

import DatePickerSelect from "./DatePickerSelect";

const FilterBlockDesktop = ({
  items,
  filters,
  dispatch,
  paymentType,
  datepicker,
  placeholder,
}) => {
  const [searchContract, setSearchContract] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventsSelect, setEventsSelect] = useState("auto");

  const handlerRemove = () => {
    if (filters) {
      setSearchContract(null), setSearchType(null);
    }
    dispatch(cleanFilteredItems(items));
  };

  useEffect(() => {
    if (searchContract || searchType) {
      dispatch(getFilters({ ...filters, searchContract, searchType }));
    }
  }, [searchContract, searchType]);

  useEffect(() => {
    if (filters !== null && items !== null) {
      dispatch(getFilteredItems(items, filters));
    }
  }, [filters]);

  const options = useMemo(() => {
    return [
      ...new Set(items && items.map((item) => item.contract.contractNumber)),
    ];
  }, [items]);

  return (
    <>
      <div className="select-block">
        <div
          className="select-block__filter"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          <span>
            <Filter />
          </span>
          Фільтри
        </div>
        <div className="select-block__item">
          {paymentType && (
            <SelectBlock
              items={[
                { label: "Нарахування", value: "income" },
                { label: "Списання", value: "expense" },
              ]}
              value={searchType}
              placeholder="Тип"
              onChange={(selected) => setSearchType(selected)}
              isDisabled={options.length > 0 ? false : true}
              event={eventsSelect}
            />
          )}
          <SelectBlock
            items={options.map((item) => ({ label: `Договір № ${item}`, value: item }))}
            value={searchContract}
            placeholder="Договір"
            onChange={(selected) =>  setSearchContract({label:`№ ${selected.value}`, value: selected.value})}
            isDisabled={options.length > 0 ? false : true}
            event={eventsSelect}
          />

          <DatePickerSelect
            items={items}
            datepicker={datepicker}
            switchState={true}
            placeholder={placeholder}
            setEventsSelect={setEventsSelect}
          />
        </div>
        <div
          className={
            filters
              ? "select-block__clear"
              : "select-block__clear select-block__clear--empty"
          }
          onClick={handlerRemove}
        >
          <span className="select-block__close"></span> Очистити фільтр
        </div>
      </div>

      {isModalVisible && (
        <ModalLayout
          isModalVisible={isModalVisible}
          children={
            <FilterBlockTablet
              setIsModalVisible={setIsModalVisible}
              contract
              datepicker={datepicker}
              switchState={true}
              paymentType={paymentType}
              placeholder={placeholder}
            />
          }
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { filters } = state.filters;

  return {
    filters,
  };
}

export default connect(mapStateToProps)(FilterBlockDesktop);

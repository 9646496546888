const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__wrap">
        <div className="loader__content">
          <div className="loader__title">
            {"Очікується відправлення данних"}
          </div>
          <div class="loader__animation">
            <div class="loader__ball-1 loader__ball"></div>
            <div class="loader__ball-2 loader__ball"></div>
            <div class="loader__ball-3 loader__ball"></div>
            <div class="loader__ball-4 loader__ball"></div>
            <div class="loader__ball-5 loader__ball"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;

import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import moment from "moment";
import { connect } from "react-redux";
import { Form, Input } from "antd";
import { postRequestAct } from "../../store/actions/userInfo";

const RequestAct = ({ userInfo, setIsModalVisible, title, dispatch }) => {
  const [form] = Form.useForm();
  const [readySend, setReadySend] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [dataForRequestAct, setDataForRequestAct] = useState({
    requestType: "act",
    date: moment(new Date()).format("YYYY-MM-DD"),
    name: "Замовлення акту звірки",
    user: null,
    company: null,
    contracts: [null],
    requestNotes: null,
  });

  useEffect(() => {
    if (userInfo?.company) {
      const arrayContracts = userInfo.company.contracts.map((item) => item.id);
      setDataForRequestAct({
        ...dataForRequestAct,
        user: userInfo.id,
        company: userInfo.company.id,
        contracts: arrayContracts,
        requestNotes: null,
      });
    }
  }, [userInfo]);

  const handlerSubmit = (e) => {
    setButtonDisable(true);
    if (userInfo) {
      dispatch(postRequestAct({ data: dataForRequestAct }))
        .then(() => {
          setButtonDisable(false);
          setIsModalVisible(true);
        })
        .catch((error) => {
          setButtonDisable(false);
        });
    }
    setReadySend(true);
  };

  return (
    <div className="request-act">
      <div className="request-act__wrap">
        <div className="request-act__body">
          <div
            className="modal__close"
            onClick={() => setIsModalVisible(false)}
          ></div>
          <div className="request-act__sent-content">
            {!readySend ? (
              <>
                <div className="request-act__title">{title}</div>
                <Form form={form} name="act" onFinish={handlerSubmit}>
                  <Form.Item className="form__field-signin" name="requestNotes">
                    <Input
                      name="requestNotes"
                      type="text"
                      placeholder={"Вкажіть період"}
                      onChange={(e) =>
                        setDataForRequestAct({
                          ...dataForRequestAct,
                          requestNotes: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                  <Button
                    disabled={buttonDisable}
                    text="Надіслати"
                    className=" button button__submit"
                    type="submit"
                  />
                </Form>
              </>
            ) : (
              <>
                <div className="request-act__title">{"Заявка надіслана"}</div>
                <div className="request-act__text">
                  {
                    "Ми прийняли вашу заявку на формування акту звірки. Мисповістимо вас, коли акт буде доступним в розділі Документи"
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(RequestAct);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import closeSidebar from "../../assets/closeSidebar.svg";
import NavSidebarOpen from "./NavSidenarOpen";
import NavSidebarClose from "./NavSidenarClose";

import {
  SHOW_DESKTOP_SIDEBAR,
  HIDE_DESKTOP_SIDEBAR,
} from "../../store/actions/types";

const NavSidebar = ({
  sidebarDesktop,
  visibleTbSidebar,
  userInfo,
  dispatch,
}) => {
  const navBarClass = visibleTbSidebar ? "show-sidebar" : "tb-sidebar";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onChangeSidebar = () => {
    sidebarDesktop
      ? dispatch({ type: HIDE_DESKTOP_SIDEBAR })
      : dispatch({ type: SHOW_DESKTOP_SIDEBAR });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 1279) {
        dispatch({ type: SHOW_DESKTOP_SIDEBAR });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {(userInfo && userInfo.active) ||
      (userInfo && !userInfo.active && window.innerWidth < 1279) ? (
        <div
          className={
            `${sidebarDesktop ? "sidebar" : "sidebar sidebar--close"}` +
            " " +
            `${windowWidth < 1279 ? navBarClass : ""}`
          }
        >
          <div className="sidebar__wrap">
            <div
              className={
                sidebarDesktop
                  ? "sidebar__close"
                  : "sidebar__close  sidebar__close--close"
              }
              onClick={onChangeSidebar}
            >
              <img src={closeSidebar} alt="" />
            </div>
            <div className="sidebar__container">
              <div className="sidebar__ellipse"></div>
              {sidebarDesktop && <NavSidebarOpen />}
              {!sidebarDesktop && <NavSidebarClose />}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

NavSidebar.propTypes = {
  visibleTbSidebar: PropTypes.bool,
  sidebarDesktop: PropTypes.bool,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { sidebar } = state,
    { userInfo } = state.userInfo;
  return {
    userInfo,
    visibleTbSidebar: sidebar.visibleTbSidebar,
    sidebarDesktop: sidebar.sidebarDesktop,
  };
};

export default connect(mapStateToProps)(NavSidebar);

import { Route, Routes, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./common/styles.scss";
import Registration from "./pages/Registration/RegistrationStep/RegistrationStep";
import Header from "./components/Header/Header";
import NewPassword from "./pages/Registration/NewPassword/NewPassword";
import SignUp from "./pages/Registration/SignUp/SignUp";
import SignIn from "./pages/Registration/SignIn/SignIn";
import ForgotPassword from "./pages/Registration/ForgotPassword/ForgotPassword";
import Verification from "./pages/Registration/Verification/Verification";
import GeneralInfo from "./pages/GeneralInfo/GeneralInfo";
import OrderEnergy from "./pages/OrderEnergy/OrderEnergy";
import TariffStructure from "./pages/TariffStructure/TariffStructure";
import Documents from "./pages/Documents/Documents";
import Askoe from "./pages/ASKOE/Askoe";
import Invoices_Payments from "./pages/Payments/Invoices_Payments";
import Profile from "./pages/Profile/Profile";
import PageNotFound from "./pages/PageNotFound";
import { connect } from "react-redux";
import React from "react";

const ProtectedRoute = ({ element, condition, redirectTo }) =>
  condition ? element : <Navigate to={redirectTo} />;

const ConditionalRoute = ({ element, condition }) =>
  condition ? element : <Navigate to="/" />;

const App = ({ userInfo, groupB }) => {
  const isUserActive = userInfo?.active;

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            !userInfo ? (
              <Registration />
            ) : isUserActive ? (
              <Navigate to="/general" />
            ) : (
              <Navigate to="/verification" />
            )
          }
        />
        <Route
          path="/general"
          element={
            <ProtectedRoute
              element={<GeneralInfo />}
              condition={isUserActive}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/sign-up"
          element={
            <ConditionalRoute element={<SignUp />} condition={!userInfo} />
          }
        />
        <Route
          path="/sign-in"
          element={
            <ConditionalRoute element={<SignIn />} condition={!userInfo} />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ConditionalRoute
              element={<ForgotPassword />}
              condition={!userInfo}
            />
          }
        />
        <Route
          path="/new-password"
          element={location.search ? <NewPassword /> : <Navigate to="/404" />}
        />
        <Route
          path="/verification"
          element={
            <ProtectedRoute
              element={<Verification />}
              condition={!isUserActive}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/order-energy"
          element={
            <ProtectedRoute
              element={<OrderEnergy />}
              condition={isUserActive}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/tariff-structure"
          element={
            <ProtectedRoute
              element={<TariffStructure />}
              condition={isUserActive}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/documents"
          element={
            <ProtectedRoute
              element={<Documents />}
              condition={isUserActive}
              redirectTo="/"
            />
          }
        />
        {!groupB && (
          <Route
            path="/askoe"
            element={
              <ProtectedRoute
                element={<Askoe />}
                condition={isUserActive}
                redirectTo="/"
              />
            }
          />
        )}
        <Route
          path="/payments"
          element={
            <ProtectedRoute
              element={<Invoices_Payments />}
              condition={isUserActive}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/profile"
          element={userInfo ? <Profile /> : <Registration />}
        />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

App.propTypes = {
  userInfo: PropTypes.shape({
    active: PropTypes.bool,
  }),
  groupB: PropTypes.bool,
};

function mapStateToProps(state) {
  const { userInfo, groupB } = state.userInfo;

  return {
    userInfo,
    groupB,
  };
}

export default connect(mapStateToProps)(App);

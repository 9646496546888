import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import moment from "moment";
import { connect } from "react-redux";
import { Form, Input } from "antd";
import {
  createConsumptionOrder,
  updateConsumptionOrder,
} from "../../store/actions/userInfo";

const OrderForGroupB = ({ setIsModalVisible, order, dispatch, contract }) => {
  const [form] = Form.useForm();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [readySend, setReadySend] = useState(false);

  const [dataForOrderConsumption, setDataForOrderConsumption] = useState({
    month: moment(order.month).format("YYYY-MM-DD"),
    contract: null,
    company: null,
    monthConsumption: [],
  });

  useEffect(() => {
    setDataForOrderConsumption({
      ...dataForOrderConsumption,
      contract: contract.id,
      company: contract.company.id,
      monthConsumption: [],
    });
  }, []);

  const handlerSubmit = (e) => {
    setButtonDisable(true);
    setReadySend(true);
    form
      .validateFields()
      .then(() => {
        if (order.totalMonthConsumption) {
          dispatch(
            updateConsumptionOrder({ data: dataForOrderConsumption }, order.id)
          )
            .then(() => {
              setButtonDisable(false);
              setTimeout(() => {
                setIsModalVisible(false);
              }, 2000);
            })
            .catch((error) => {
              setButtonDisable(false);
            });
        } else {
          dispatch(createConsumptionOrder({ data: dataForOrderConsumption }))
            .then(() => {
              setButtonDisable(false);
              setTimeout(() => {
                setIsModalVisible(false);
              }, 2000);
            })
            .catch((error) => {
              setButtonDisable(false);
            });
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="order-callback">
      <div className="order-callback__wrap">
        <div className="order-callback__body">
          <div
            className="modal__close"
            onClick={() => setIsModalVisible(false)}
          ></div>
          <div className="order-callback__sent-content">
            {!readySend ? (
              <>
                <div className="order-callback__title order-callback__title--align">
                  {moment(order.month)
                    .format("MMMM YYYY")
                    .charAt(0)
                    .toUpperCase() +
                    moment(order.month).format("MMMM YYYY").slice(1)}
                </div>
                <div className="order-callback__text">
                  Вкажіть загальну суму споживання в кВт*год електричної енергії
                  по всіх точках обліку за Договором
                </div>
                <Form form={form} name="order" onFinish={handlerSubmit}>
                  <Form.Item
                    className="form__field-signin"
                    name="consumption-orders"
                    rules={[
                      {
                        required: true,
                        message: "Поле обов`язкове для заповнення",
                      },
                      { pattern: /([0-9.,]{1,})/, message: "Невірний формат" },
                    ]}
                  >
                    <Input
                      name="consumption-orders"
                      type="number"
                      placeholder="Введіть значення"
                      onChange={(e) =>
                        setDataForOrderConsumption({
                          ...dataForOrderConsumption,
                          totalMonthConsumption: +e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                  <Button
                    disabled={buttonDisable}
                    text="Додати"
                    className=" button button__submit"
                    type="submit"
                  />
                </Form>
              </>
            ) : (
              <div className="order-callback__title">Відправлено</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;
  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(OrderForGroupB);

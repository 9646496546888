import React, {useState, useEffect} from 'react';
import Button from '../../UI/Button';
import moment from 'moment';
import { connect } from "react-redux";
import { Form, Input} from 'antd';
import { postRequestAct } from "../../store/actions/userInfo";


const RequestCallback = ({userInfo, setIsModalVisible, title, dispatch}) => {
	const [form] = Form.useForm();
	const [buttonDisable, setButtonDisable] = useState(false);
	const [readySend, setReadySend] = useState(false);
	
	const [dataForRequestCallback, setDataForRequestCallback] = useState({
		requestType: "callback",
		date: moment(new Date()).format('YYYY-MM-DD'),
		name: "Зворотній зв'язок",
		user: null,
		company: null
	});

	useEffect(() => {
		setDataForRequestCallback({...dataForRequestCallback, 
			user: userInfo.id, 
			company: userInfo?.company?.id ?? null})
	}, [])

	const handlerSubmit = (e) =>{
		setButtonDisable(true)
		setReadySend(true)
		form.validateFields().then(() => {
				dispatch(postRequestAct( {"data": dataForRequestCallback})).then(() => {
					setButtonDisable(false)
				})
				.catch(error => {
					setButtonDisable(false)
				}) 
		})
		.catch(error => {
			
		})
	}

	return (
		<div className="request-callback">
			<div className="request-callback__wrap">
					<div className="request-callback__body">
						<div className="modal__close" onClick={()=> setIsModalVisible(false)} ></div>
							<div className="request-callback__sent-content">
								{!readySend ? 
								<>
								<div className="request-callback__title">{title}</div>
									<Form 
									form={form}
									name="callback"
									onFinish={handlerSubmit}>
									<Form.Item
										className='form__field-signin'
										name='requestNotes'
										rules={[{required: true, message: 'Поле обов`язкове для заповнення'},
										{pattern: /([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})/, message: "Невірний формат" }]}
									>
										<Input
											name='requestNotes'
											type='text'
											placeholder={title}
											onChange={(e) => setDataForRequestCallback({...dataForRequestCallback, requestNotes: e.target.value })}
										/>
									</Form.Item>
									<Button disabled={buttonDisable} text='Надіслати'  className=" button button__submit" type="submit"/>
								</Form>
								
								</>
								:
								<div className="request-callback__title">
									 Дякуємо, з Вами скоро зв'яжуться!
								</div>
								}
							</div>
					</div>
			</div>
		</div>
	)
  };
  
  function mapStateToProps(state) {
	const {userInfo} = state.userInfo;
	
	  return {
		userInfo
		}
}
  
  export default connect(mapStateToProps)(RequestCallback);
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../UI/Button";
import Icon from "../../../UI/Icon";
import safety from "../../../assets/safety.svg";
import { Form, Input } from "antd";

import { connect } from "react-redux";
import { resetPassword } from "../../../store/actions/auth";

const ContentNewPassword = ({ dispatch }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [error, setError] = useState("Обов`язкове поле");
  const [buttonDisable, setButtonDisable] = useState(false);
  const code = window.location.href.split("=").pop();

  const handlerSubmit = (event) => {
    setButtonDisable(true);
    form
      .validateFields()
      .then((values) => {
        values["code"] = code;
        dispatch(resetPassword(values))
          .then(() => navigate("/general"))
          .catch((e) => {});
      })
      .catch((e) => {
        setButtonDisable(false);
      });
  };

  return (
    <div className="registration__right">
      <div className="registration__block--form">
        <div className="form">
          <Icon img={safety} className="icon" />
          <div className="form__title">Створіть новий пароль</div>
          <div className="form__text">
            Будь ласка, створіть новий пароль. Він має містити від 8 до 12 символів. Дозволені символи: a-z, A-Z (латиниця), цифри 0-9, крапка (.). 
          </div>

          <Form form={form} name="new-password" onFinish={handlerSubmit}>
            <Form.Item
              className="form__field-pass"
              name="password"
              rules={[
                { required: true, message: error },
                {
                  pattern: /^([A-z0-9_\.]{8,12})$/,
                  message:
                    "Кількість символів 8-12, a-z, A-Z (латиниця), цифри 0-9, крапка (.), символи (_)",
                },
              ]}
            >
              <Input.Password
                minLength="8"
                maxLength="12"
                placeholder="Введіть пароль"
              />
            </Form.Item>

            <Form.Item
              className="form__field-pass"
              name="passwordConfirmation"
              rules={[
                {
                  required: true,
                  message: error,
                },
                {
                  pattern: /^([A-z0-9_\.]{8,12})$/,
                  message:
                    "Кількість символів 8-12, a-z, A-Z (латиница), цифри 0-9, крапка (.), символи (_)",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Введені вами паролі не збігаються!");
                  },
                }),
              ]}
            >
              <Input.Password
                minLength="8"
                maxLength="12"
                placeholder="Введіть пароль повторно"
              />
            </Form.Item>

            <Button
              disabled={buttonDisable}
              text="Зберегти і ввійти"
              type="submit"
              className="button  button__submit"
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps)(ContentNewPassword);

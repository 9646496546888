import UserInfoService from "../services/userInfo.service";
import {
  GET_USER,
  GET_ITEMS,
  GET_ASKOE_POINTS,
  GET_CONTRACT,
} from "../actions/types";
import { getGroupedDataByDate, getComputedDataPerPeriod } from "./calculation";
import moment from "moment";

export const getUserInfo = () => (dispatch) => {
  return UserInfoService.getUserInfo().then(
    (response) => {
      dispatch({
        type: GET_USER,
        payload: { userInfo: response.data, groupB: response.groupB },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const updateUserInfo = (payload, id) => (dispatch) => {
  return UserInfoService.updateUserInfo(payload, id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getInvoicesInfo = (id, value) => (dispatch) => {
  return UserInfoService.getInvoicesInfo(id, value).then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getPaymentsInfo = (id) => (dispatch) => {
  return UserInfoService.getPaymentsInfo(id).then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getDocumentsInfo = (id) => (dispatch) => {
  return UserInfoService.getDocumentsInfo(id).then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getContractInfo = () => (dispatch) => {
  return UserInfoService.getContractInfo().then(
    (response) => {
      dispatch({
        type: GET_CONTRACT,
        payload: { contractInfo: response.data },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const askoePointsInfo = (id) => (dispatch) => {
  return UserInfoService.askoePointsInfo(id).then(
    (response) => {
      dispatch({
        type: GET_ASKOE_POINTS,
        payload: { askoePoints: response.data },
      });

      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const askoePointsByContract = (id) => (dispatch) => {
  return UserInfoService.askoePointsByContract(id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const postRequestAct = (items) => (dispatch) => {
  return UserInfoService.postRequestAct(items).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getDiscountInfo = (id) => (dispatch) => {
  return UserInfoService.getDiscountInfo(id).then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getPricesInfo = () => (dispatch) => {
  return UserInfoService.getPricesInfo().then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getPricesRange = (firstDay, lastDay) => (dispatch) => {
  return UserInfoService.getPricesRange(firstDay, lastDay).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionByCompany =
  (id, firstDay, lastDay) => (dispatch) => {
    return UserInfoService.getConsumptionByCompany(id, firstDay, lastDay).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const getConsumptionByContract =
  (id, firstDay, lastDay) => (dispatch) => {
    return UserInfoService.getConsumptionByContract(id, firstDay, lastDay).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const getConsumptionOrderByCompany =
  (id, firstDay, lastDay) => (dispatch) => {
    return UserInfoService.getConsumptionOrderByCompany(
      id,
      firstDay,
      lastDay
    ).then(
      (response) => {
        // dispatch({
        // 	type: GET_ITEMS,
        // 	payload: { items: response.data},
        // });
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const getConsumptionOrderByContract =
  (id, firstDay, lastDay) => (dispatch) => {
    return UserInfoService.getConsumptionOrderByContract(
      id,
      firstDay,
      lastDay
    ).then(
      (response) => {
        // dispatch({
        // 	type: GET_ITEMS,
        // 	payload: { items: response.data},
        // });
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const getConsumptionOrderByPoint =
  (id, firstDay, lastDay) => (dispatch) => {
    return UserInfoService.getConsumptionOrderByPoint(
      id,
      firstDay,
      lastDay
    ).then(
      (response) => {
        // dispatch({
        // 	type: GET_ITEMS,
        // 	payload: { items: response.data},
        // });
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const createConsumptionOrder = (items) => (dispatch) => {
  return UserInfoService.createConsumptionOrder(items).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const updateConsumptionOrder = (items, id) => (dispatch) => {
  return UserInfoService.updateConsumptionOrder(items, id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionByPoint = (id, firstDay, lastDay) => (dispatch) => {
  return UserInfoService.getConsumptionByPoint(id, firstDay, lastDay).then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionPerDay = (firstDay, lastDay) => () => {
  return UserInfoService.getConsumptionPerDay(firstDay, lastDay).then(
    (response) => {
      let days = [];
      let startingMoment = moment(firstDay);
      while (startingMoment <= moment(lastDay)) {
        days.push(startingMoment.format("MM-DD"));
        startingMoment.add(1, "days");
      }

      const groupedData = getGroupedDataByDate(response.data, "day");

      const { cost, amount } = getComputedDataPerPeriod(groupedData, days);

      return Promise.resolve({ cost, amount });
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionPerMonth = (firstDay, lastDay) => () => {
  return UserInfoService.getConsumptionPerMonth(firstDay, lastDay).then(
    (response) => {
      const month = Array.apply(0, Array(12)).map((_, i) => {
        return moment(moment(firstDay).month(i))
          .startOf("month")
          .format("YYYY-MM");
      });

      const groupedData = getGroupedDataByDate(response.data, "month");

      const { cost, amount } = getComputedDataPerPeriod(groupedData, month);

      return Promise.resolve({ cost, amount });
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionPerDayByContract = (firstDay, lastDay, id) => () => {
  return UserInfoService.getConsumptionPerDayByContract(
    firstDay,
    lastDay,
    id
  ).then(
    (response) => {
      let days = [];
      let startingMoment = moment(firstDay);
      while (startingMoment <= moment(lastDay)) {
        days.push(startingMoment.format("MM-DD"));
        startingMoment.add(1, "days");
      }

      const groupedData = getGroupedDataByDate(response.data, "days");

      const { cost, amount } = getComputedDataPerPeriod(groupedData, days);

      return Promise.resolve({ cost, amount });
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getConsumptionPerMonthByContract =
  (firstDay, lastDay, id) => () => {
    return UserInfoService.getConsumptionPerMonthByContract(
      firstDay,
      lastDay,
      id
    ).then(
      (response) => {
        const month = Array.apply(0, Array(12)).map((_, i) => {
          return moment(moment(firstDay).month(i))
            .startOf("month")
            .format("YYYY-MM");
        });

        const groupedData = getGroupedDataByDate(response.data, "month");

        const { cost, amount } = getComputedDataPerPeriod(groupedData, month);

        return Promise.resolve({ cost, amount });
      },
      (error) => {
        return Promise.reject();
      }
    );
  };

export const getGeneralConsumptionPerMonth = () => (dispatch) => {
  return UserInfoService.getGeneralConsumptionPerMonth().then(
    (response) => {
      dispatch({
        type: GET_ITEMS,
        payload: { items: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

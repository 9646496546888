import React, { useState, useEffect, useMemo, useRef } from "react";
import Icon from "../../UI/Icon";
import dropdown from "../../assets/dropdown.svg";
import { connect } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "antd";
import moment from "moment";
import uk from "date-fns/locale/uk";
registerLocale("uk", uk);

import { getFilters } from "../../store/actions/filters";

const DatePickerSelect = ({
  items,
  filters,
  dispatch,
  datepicker,
  switchState,
  placeholder,
  controlSection = true,
  checked,
  firstMonth,
  noDisabled = false,
}) => {
  const [yearDate, setYearDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(switchState);
  const [filterDate, setFilterDate] = useState(false);
  const dateStartRange = moment(startDate).format("DD.MM.YY");
  const dateEndRange = moment(endDate).format("DD.MM.YY");

  const currentYear = moment().format("YYYY");
  const yearsList = [];
  const ref = useRef();
  const refYear = useRef();

  const itemsYears = useMemo(() => {
    return [
      ...new Set(
        items &&
          _.sortBy(items, "date" || "documentDate").map((item) => {
            if (item.documentDate) {
              return moment(item.documentDate).format("YYYY");
            } else {
              return moment(item.date).format("YYYY");
            }
          })
      ),
    ];
  }, [items]);

  if (itemsYears) {
    for (let i = +itemsYears?.[0]; i <= currentYear; i++) {
      yearsList.push(i.toString());
    }
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const openFilterDate = (e) => {
    filterDate ? setFilterDate(false) : setFilterDate(true);
  };

  useEffect(() => {
    if (!filters) {
      setYearDate(null);
      setStartDate(null);
      setEndDate(null);
    }
  }, [filters]);

  useEffect(() => {
    if (yearDate || (startDate && endDate)) {
      dispatch(getFilters({ ...filters, yearDate, startDate, endDate }));
    }

    if (yearDate || (startDate && endDate)) {
      setFilterDate(false);
    }
  }, [startDate, endDate, yearDate]);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterDate(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  useEffect(() => {
    const target = document.querySelector(`[data-type="active"]`);

    if (target) {
      target.parentNode.scrollTop = target.offsetTop - 28;
    }
  }, [filterDate]);

  return (
    <>
      <div className="datepicker" ref={ref}>
        <div
          className={
            items?.length > 0 || noDisabled
              ? "datepicker__select"
              : "datepicker__select datepicker--disabled"
          }
          onClick={openFilterDate}
        >
          {checked && !controlSection ? (
            <input
              type="text"
              className="datepicker__input datepicker__input--day"
              placeholder={moment(firstMonth).format("DD.MM.YY")}
              defaultValue={startDate !== null ? dateStartRange : ""}
              data-type="day"
            />
          ) : (
            <input
              type="text"
              className={
                selectedMonth
                  ? "datepicker__input datepicker__input--year"
                  : "datepicker__input"
              }
              placeholder={placeholder}
              defaultValue={
                (!selectedMonth && startDate !== null
                  ? `${dateStartRange} - ${
                      endDate !== null ? dateEndRange : ""
                    }`
                  : "") ||
                (selectedMonth && yearDate !== null ? `${yearDate}` : "")
              }
            />
          )}
          {placeholder === "Період" ? (
            <Calendar />
          ) : (
            <Icon
              img={dropdown}
              className={
                filterDate
                  ? "datepicker__select-arrow--open"
                  : "datepicker__select-arrow"
              }
            />
          )}
        </div>
        {filterDate && (
          <div className="datepicker__wrap">
            {datepicker && switchState && (
              <div className="datepicker__switch">
                <Switch
                  defaultChecked={selectedMonth}
                  onChange={() =>
                    selectedMonth
                      ? (setSelectedMonth(false), setYearDate(null))
                      : (setSelectedMonth(true),
                        setStartDate(null),
                        setEndDate(null))
                  }
                />
                По рокам
              </div>
            )}

            {!selectedMonth && (
              <div className="datepicker-calendar">
                {checked && !controlSection ? (
                  <DatePicker
                    selected={startDate}
                    startDate={startDate}
                    onChange={(date) => (setStartDate(date), setEndDate(date))}
                    locale="uk"
                    inline
                  />
                ) : (
                  <DatePicker
                    selected={startDate}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    calendarStartDay={1}
                    locale="uk"
                    inline
                  />
                )}
              </div>
            )}

            {selectedMonth && (
              <div className="datepicker-year">
                <ul className="datepicker-year__list" ref={refYear}>
                  {yearsList?.map((year, i) => (
                    <li
                      key={year}
                      className="datepicker-year__item"
                      data-type={
                        year === yearDate || year === moment().format("YYYY")
                          ? "active"
                          : "default"
                      }
                      onClick={(e) => (
                        setYearDate(e.target.innerHTML), setFilterDate(false)
                      )}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { items } = state.userInfo,
    { filters } = state.filters;

  return {
    items,
    filters,
  };
}

export default connect(mapStateToProps)(DatePickerSelect);

import React from "react";
import PropTypes from "prop-types";

const RegistrationLayout = ({ ellipse, children }) => {
  return (
    <div className="registration">
      <div className={ellipse ? "ellipse" : "ellipse--bottom"}></div>
      <div className="container">
        <div className="registration__wrap">{children}</div>
      </div>
    </div>
  );
};

RegistrationLayout.propTypes = {
  children: PropTypes.node,
  link: PropTypes.node,
};

export default RegistrationLayout;

import React, { useEffect, useMemo } from "react";
import moment from "moment";
import "moment/locale/uk";

const locale = typeof window.LOCALE === "undefined" ? "ua" : window.LOCALE;
const momentLocale = locale === "ua" ? "uk" : locale;
moment.locale(momentLocale, {
  week: {
    dow: 1,
  },
});

const momentDays = {
  desktop: moment()._locale.weekdays(true),
};

let calendarLegend = [];

for (let i = 0; i < 7; i++) {
  calendarLegend.push({
    desktop: momentDays.desktop[i],
  });
}

const CalendarBlock = ({ days, month, cost, consumption }) => {
  const style = { gridColumnStart: moment(month).startOf("month").format("d") };

  useEffect(() => {}, [consumption, days]);
  const daysRange = useMemo(() => {
    let dayArr = [];
    for (let i = 1; i <= month.endOf("month").format("DD"); i++) {
      i < 10 ? (i = "0" + i) : i;
      dayArr.push(i.toString());
    }
    return dayArr;
  }, []);

  return (
    <div className="calendar">
      <div className="calendar__legend">
        {calendarLegend.map((day, index) => {
          return (
            <div className="calendar__legend-item" key={index}>
              <div className="calendar__legend-day">{day.desktop}</div>
            </div>
          );
        })}
      </div>
      <div className="calendar__week">
        {daysRange.map((day, index) => {
          return (
            <div
              className="calendar__week-item"
              style={index === 0 ? style : {}}
              key={index}
            >
              <div className="calendar__week-item-wrap">
                <div className="calendar__week-day">
                  {day}.{moment(month).startOf("month").format("MM")}
                </div>
                <div className="calendar__day">
                  {cost &&
                    cost.map((item, i) => {
                      if (i === index)
                        return (
                          <div key={i} className="calendar__day-value">
                            {Math.round(item * 100) / 100} <span>грн</span>
                          </div>
                        );
                    })}
                  {consumption &&
                    consumption.map((item, i) => {
                      if (i === index)
                        return (
                          <div key={i} className="calendar__day-value">
                            {Math.round(item * 100) / 100} <span>кВт*год</span>
                          </div>
                        );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarBlock;

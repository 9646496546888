import React, { useCallback } from "react";
import moment from "moment";

const pattern = /^\d+[.]?\d{0,3}$/;

const TableEditOrder = ({ headings, rows, setRowsTable, setTotal, item }) => {
  const style =
    moment(item.month).format("YYYY-MM") === moment().format("YYYY-MM");

  const handleInputChange = useCallback(
    (indexRow, indexInput) => (e) => {
      const value = e.target.value.replace(",", ".");

      if (pattern.test(value)) {
        const newArr = [...rows];

        newArr[indexRow].consumption[indexInput] = value;

        setRowsTable(newArr);

        let sum = rows
          .map((row) =>
            row.consumption.reduce((a, b) => parseFloat(a) + parseFloat(b))
          )
          .reduce((a, b) => parseFloat(a) + parseFloat(b));

        setTotal(sum);
      }
    },
    [rows]
  );

  return (
    <>
      <table className="table-edit">
        <thead className="table-order__thead">
          <tr>
            <td></td>
            {headings &&
              headings.map((heading, i) => {
                return (
                  <th
                    key={i}
                    className={
                      style && i < moment().date()
                        ? `table-edit__td-input table-edit__td-input--hide`
                        : `table-edit__td-input`
                    }
                  >
                    {heading}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((item, index) => (
              <tr key={index}>
                <th className="table-edit__th-body">{item.time}</th>
                {item.consumption.map((amount, i) => {
                  let value = amount === 0 ? amount.toFixed(2) : amount;

                  return (
                    <td
                      key={i}
                      className={
                        style && i < moment().date()
                          ? `table-edit__td-input table-edit__td-input--hide`
                          : `table-edit__td-input`
                      }
                    >
                      <input
                        type="text"
                        value={value}
                        onChange={handleInputChange(index, i)}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default TableEditOrder;

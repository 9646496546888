import PropTypes from "prop-types";
import React, { useEffect } from "react";
import NavSidebar from "../components/NavSidebar/NavSidebar";
import StubBlock from "../components/blocks/StubBlock";
import { getUserInfo } from "../store/actions/userInfo";
import { connect } from "react-redux";

const MainLayout = ({
  children,
  title,
  text,
  userInfo,
  sidebarDesktop,
  visibleTbSidebar,
  className,
  profile = false,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <div className="main">
      <div className="container">
        <NavSidebar />
        <div
          className={
            visibleTbSidebar ? "main__wrap main__wrap--none" : "main__wrap"
          }
        >
          <div
            className={
              sidebarDesktop
                ? "main__content"
                : "main__content main__content--open"
            }
          >
            {(userInfo && userInfo.company) || profile ? (
              <>
                <div className="main__header">
                  <div className={"main__title" + " " + className}>{title}</div>
                  <div className="main__text">{text}</div>
                </div>
                {children}
              </>
            ) : (
              <StubBlock
                title="У нас немає активних Договорів"
                text="На жаль, на даний момент немає доступу до активних договорів вашої компанії. 
							Будь ласка, спробуйте переглянути інформацію пізніше, або повідомте нас про помилку, відправивши запит"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sidebar } = state,
    { userInfo, groupB } = state.userInfo;

  return {
    sidebarDesktop: sidebar.sidebarDesktop,
    visibleTbSidebar: sidebar.visibleTbSidebar,
    userInfo,
  };
};

MainLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  visibleSidebar: PropTypes.bool,
};
export default connect(mapStateToProps)(MainLayout);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import Button from "../../UI/Button";
import RequestCallback from "../modals/RequestCallback";
import ModalLayout from "../../template/ModalLayout";

import { HIDE_SIDEBAR, NONAUTH_HEADER } from "../../store/actions/types";
import { logout } from "../../store/actions/auth";

const NavSidebarOpen = ({ userInfo, groupB, dispatch }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  const hideSidebar = () => {
    dispatch({ type: HIDE_SIDEBAR });
  };

  const handlerLogout = () => {
    dispatch(logout()).then(() => {
      dispatch({ type: HIDE_SIDEBAR });
      dispatch({ type: NONAUTH_HEADER });
      navigate("/");
    });
  };

  const sendRequestForm = () => {
    setButtonDisable(true);
    setIsModalVisible(true);
  };

  const phone =
    userInfo.active &&
    userInfo.manager.phone.slice(0, 3) +
      " " +
      userInfo.manager.phone.slice(3, 6) +
      " " +
      userInfo.manager.phone.slice(6, 9) +
      " " +
      userInfo.manager.phone.slice(9, 11) +
      " " +
      userInfo.manager.phone.slice(11);

  return (
    <>
      {userInfo && userInfo.active && (
        <>
          <div className="sidebar__logo">
            <Link to="/" className="sidebar__logo-icon"></Link>
          </div>

          <div className="sidebar__separator"></div>
          <div className="sidebar__company-title">{userInfo?.companyName}</div>
          <div className="sidebar__separator"></div>
          <ul className="sidebar__list">
            <li
              className={
                splitLocation[1] === "general"
                  ? "sidebar__list-item _active"
                  : "sidebar__list-item"
              }
              onClick={hideSidebar}
            >
              <NavLink to="/general" className="sidebar__link">
                Дані по споживанню
              </NavLink>
            </li>
            <li
              className={
                splitLocation[1] === "tariff-structure"
                  ? "sidebar__list-item _active"
                  : "sidebar__list-item"
              }
              onClick={hideSidebar}
            >
              <NavLink to="/tariff-structure" className="sidebar__link">
                Дані по вартості
              </NavLink>
            </li>

            <li
              className={
                splitLocation[1] === "order-energy"
                  ? "sidebar__list-item _active"
                  : "sidebar__list-item"
              }
              onClick={hideSidebar}
            >
              <NavLink to="/order-energy" className="sidebar__link">
                Замовлення електроенергії
              </NavLink>
            </li>
            <li
              className={
                splitLocation[1] === "payments"
                  ? "sidebar__list-item _active "
                  : "sidebar__list-item "
              }
              onClick={hideSidebar}
            >
              <NavLink to="/payments" className="sidebar__link">
                Нарахування і платежі
              </NavLink>
            </li>
            <li
              className={
                splitLocation[1] === "documents"
                  ? "sidebar__list-item _active "
                  : "sidebar__list-item "
              }
              onClick={hideSidebar}
            >
              <NavLink to="/documents" className="sidebar__link">
                Документи
              </NavLink>
            </li>
            {!groupB && (
              <li
                className={
                  splitLocation[1] === "askoe"
                    ? "sidebar__list-item _active "
                    : "sidebar__list-item "
                }
                onClick={hideSidebar}
              >
                <NavLink to="/askoe" className="sidebar__link">
                  Споживання та дані АСКОЕ
                </NavLink>
              </li>
            )}
          </ul>
          <div className="sidebar__separator"></div>
          <div className="sidebar__info">
            <div className="sidebar__manager">Персональний менеджер</div>
            <div className="sidebar__info-person">
              <div className="sidebar__contact">
                <div className="sidebar__contact-name">
                  {userInfo.manager.firstName} {userInfo.manager.lastName}
                </div>
                <div className="sidebar__phone">
                  <a href={`tel:${userInfo.manager.phone}`}>{phone}</a>
                </div>
              </div>
              <Button
                sendRequest={sendRequestForm}
                text="Оформити звернення"
                className="button button__empty"
              ></Button>
            </div>
          </div>
          <div className="sidebar__separator"></div>

          <div className="sidebar__separator"></div>

          {isModalVisible && (
            <ModalLayout
              isModalVisible={isModalVisible}
              className={"background"}
              children={
                <RequestCallback
                  setIsModalVisible={setIsModalVisible}
                  title="Опишіть ваше питання"
                />
              }
            />
          )}
        </>
      )}

      <div className="sidebar__button">
        <Button
          text="Вийти"
          sendRequest={handlerLogout}
          className="button button__exit"
        ></Button>
      </div>
    </>
  );
};

NavSidebarOpen.propTypes = {
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { userInfo, groupB } = state.userInfo;

  return {
    userInfo,
    groupB,
  };
};

export default connect(mapStateToProps)(NavSidebarOpen);

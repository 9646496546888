import React from 'react';
import Button  from '../../UI/Button';






const TableIndicator = ({button, headings, rows}) => {

	 

	return (

		<>
			<div className="general-info__measure">
				{button ? 
					<div className="general-info__table-button">
						<Button text={button} className='button button__excel'></Button> 
					</div>
					: null
				}
			</div>

			<div className='table-consumption'>
				<table >
					<thead>
						<tr>
							{headings && headings.map((date)=> <th key={date} className="table-consumption__th">{date}</th>)}
						</tr>
					</thead>
					<tbody>
						<>
							{rows && rows.map((row) => 
								<tr key={row.consumptionSelected}>
									<th className="table-consumption__th">{row.accessPoint}</th>
									<td className="table-consumption__td">{row.consumptionSelected}</td>
									<td className="table-consumption__td">{row.counter?.[0] ?? 0}</td>
									<td className="table-consumption__td">{row.counter?.[row.counter.length-1] ?? 0}</td>
									<td className="table-consumption__td">{row?.coef ?? 0}</td>
									<td className="table-consumption__td">{(row.counter[row.counter.length-1] - row.counter[0])*row.coef}</td>
								</tr>
							)}
						</>
					</tbody>
				
				</table>
			</div>
			{button ? 
					<div className="general-info__table-button--mb">
						<Button text={button} className='button button__excel'></Button> 
					</div>
					: null
				}
		
		</>
		
	
	)}

  export default  TableIndicator;

import Button from '../../UI/Button';
import React, {useState} from 'react';
import RequestCallback from '../modals/RequestCallback';
import ModalLayout from '../../template/ModalLayout';


const StubBlock = ({title, text}) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);

	const openRequestForm = () => {
		setButtonDisable(true)
		setIsModalVisible(true)
	}

	return (
		<>
		<div className="stub">
			<div className="stub__wrap">
				<div className="stub__content">
					<div className="stub__title">{title}</div>
					<div className="stub__text">
						{text}
					</div>
				</div>
				<Button text='Написати' className='button button__submit' sendRequest={openRequestForm}/>
			</div>
		</div>

		{isModalVisible && <ModalLayout 
			isModalVisible={isModalVisible}
			className={'background'}
			children={<RequestCallback setIsModalVisible={setIsModalVisible}
			title='Опишіть ваше питання'
			/>}
		/>}
	
		</>
	)}

	
  export default StubBlock;
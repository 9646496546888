import React , {useState}from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/Button';
import profile from '../../assets/profile.svg';
import notification from '../../assets/notification.svg';
import {ReactComponent as Menu} from "../../assets/menu.svg";
import {ReactComponent as Close} from "../../assets/close.svg";
import {ReactComponent as Logo} from "../../assets/logo.svg";

import {SHOW_SIDEBAR, HIDE_SIDEBAR, NONAUTH_HEADER} from "../../store/actions/types";
import { logout } from "../../store/actions/auth";





const Header = ({visibleTbSidebar, isHeader,  userInfo, isLoggedIn, dispatch}) => {
	const [isMenuIcon, setIsMenuIcon] = useState(true);
	const navigate = useNavigate();

	const openSidebar = () => {
		if(isMenuIcon) {
			dispatch({type: SHOW_SIDEBAR});
			setIsMenuIcon(false)
		} else {
			dispatch({type:  HIDE_SIDEBAR})
			setIsMenuIcon(true)
		}
	}
	const handlerLogout = () =>{
		dispatch(logout()).then(()=> {
			dispatch({type: HIDE_SIDEBAR});
			dispatch({type: NONAUTH_HEADER});
			navigate('/');
		})
		
	}
	
	return (
	  <header className="header">
		  <div className="container">

		  	{isHeader || isLoggedIn ?
				<div className="header__auth"> 
					{userInfo && userInfo.active &&
						<div className="header__auth-logo">
							<Link to="/"><Logo/></Link>
						</div>
					}
					{userInfo && !userInfo.active &&
						<div className="header__logo" >
							<Link to={userInfo.active ?  '/' : '/verification' } className="header__logo-icon" ></Link>
						</div> 
					}
					
					<div className="header__buttons">
						
						<Button text='Вийти'  className="button button__exit" sendRequest={handlerLogout}></Button>
				
						<div className="header__controls">
							{/* <div className="header__notification">
								<Button  img={notification} className="button button__control"></Button>
							</div>	
								<div className="header__separator"></div> */}
							<div className="header__profile">
								<Link to="/profile" className="">
									<Button  img={profile} className="button button__control"></Button>
								</Link>
							</div>
						</div>
						
							<div className="header__burger">
								<div className="header__burger-wrap" onClick={openSidebar}>
								{visibleTbSidebar ? <Close/> : <Menu/> }
								</div>
							</div>
						
					</div>
				</div>
			  :
				<div className="header__non-auth">
					<div className="header__logo">
						<Link to="/" className="header__logo-icon"></Link>
					</div>
					<div className="header__link">
						<a href='https://trading.kness.energy/' rel="noreferrer" className="button header__link-back" target='_blank'> Назад до сайту KNESS </a>
					</div>
				</div>
			}
			
		  </div>
	  </header>
	)
  }


  Header.propTypes = {
	visibleTbSidebar: PropTypes.bool,
	isHeader: PropTypes.bool, 
	userInfo:PropTypes.object,  
	isLoggedIn: PropTypes.bool	
  };

  const mapStateToProps = (state) =>{
	const 
		  {isHeader} = state.header,
		  {userInfo} =  state.userInfo,
		  {isLoggedIn} = state.auth,
		  {visibleTbSidebar} = state.sidebar;
	
	return {
		visibleTbSidebar,
		isHeader,
		isLoggedIn,
		userInfo
		
	};
}
  

export default  connect(mapStateToProps)(Header);
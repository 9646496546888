import {
  GET_USER,
  GET_ITEMS,
  GET_FILTERED_ITEMS,
  GET_ASKOE_POINTS,
  GET_CONTRACT,
  LOGOUT_USER,
} from "../actions/types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const groupB = JSON.parse(localStorage.getItem("groupB"));

const initialState = userInfo
  ? {
      userInfo,
      items: null,
      filteredItems: null,
      askoePoints: null,
      askoePointsAmount: null,
      contractInfo: null,
      groupB: groupB,
    }
  : {
      userInfo: null,
      items: null,
      filteredItems: null,
      askoePoints: null,
      askoePointsAmount: null,
      contractInfo: null,
      groupB: null,
    };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        userInfo: payload.userInfo,
        groupB: payload.groupB,
      };
    case GET_ITEMS:
      return {
        ...state,
        items: payload.items,
        filteredItems: payload.items,
      };

    case GET_FILTERED_ITEMS:
      return {
        ...state,
        filteredItems: payload.filteredItems,
      };

    case GET_ASKOE_POINTS:
      return {
        ...state,
        askoePoints: payload.askoePoints,
      };

    case GET_CONTRACT:
      return {
        ...state,
        contractInfo: payload.contractInfo,
      };

    case LOGOUT_USER:
      return {
        ...state,
        userInfo: null,
      };

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import { ReactComponent as Arrow } from "../../assets/arrow-calendar.svg";
import { ReactComponent as Arrow2 } from "../../assets/arrow2.svg";
import ReactPaginate from "react-paginate";
import FileSaver from "file-saver";
import moment from "moment";
import { requestsUrl } from "../../constants/contants";

const TableDiscount = ({ data, className, headings }) => {
  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + 10;
      setCurrentItems(data.slice(itemOffset, endOffset));

      setPageCount(Math.ceil(data.length / 10));
    }
  }, [itemOffset, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % data.length;

    setItemOffset(newOffset);
  };

  useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [data]);

  return (
    <>
      <div className={className}>
        <table className="table">
          <thead>
            <tr>
              {headings.map((date, i) => (
                <th key={i} className="table__th">
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {currentItems &&
              currentItems.map((item, i) => (
                <tr key={i}>
                  <th className="table__th">
                    {moment(item.date)
                      .format("MMMM YYYY")
                      .charAt(0)
                      .toUpperCase() +
                      moment(item.date).format("MMMM YYYY").slice(1)}
                  </th>
                  <td className="table__td">
                    {item.meteringPoint.contract.contractNumber}{" "}
                  </td>
                  <td className="table__td">{item.meteringPoint.name} </td>
                  <td className="table__td">{item.invoice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "} грн</td>
                  <td className="table__td">{item.discount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "} грн</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ReactPaginate
        className="pagination"
        nextLabel={<Arrow />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={<Arrow2 />}
        marginPagesDisplayed={3}
        forcePage={currentPage}
      />
    </>
  );
};

export default TableDiscount;

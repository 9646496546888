import React,  {useState, useEffect} from 'react';
import ModalLayout from '../../template/ModalLayout';
import AskoeModal from '../modals/AskoeModal';
import { connect } from "react-redux";





const AskoeList = ({askoePoints}) => {
	const [displayChildren, setDisplayChildren] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [poindData, setPointData] = useState(null);

	const showModal = (item) => {
		 setIsModalVisible(true)
		 setPointData(item)
	};

	return (
		<>
		<ul className="askoe__list">
			{askoePoints && askoePoints.map((item) => {
				
			return (
				<React.Fragment key={item.id}>
				<li   className="askoe__item"  >
					{item.relatedPoints.length > 0 ?
						<div
						
							onClick={() => {
								setDisplayChildren({
								...displayChildren,
								[item.name]: !displayChildren[item.name]
								
								});
							}}
							className= {displayChildren[item.name] ? "askoe__item-button" : "askoe__item-button askoe__item-button--open"}
						></div>
						:
						<>
							<div  className={item.enabled ? "askoe__item-last askoe__item-last--plus" : "askoe__item-last askoe__item-last--minus"}></div>
						</>
					}
				
				<span   className={!item.parentPoint ? "askoe__item-parent" : "askoe__item-child"} onClick={() => {showModal(item)}}>{item.name}</span>
				
				
				{displayChildren[item.name] && item.relatedPoints && <AskoeList askoePoints={item.relatedPoints}   onClick={() => {showModal(item)}} setIsModalVisible={setIsModalVisible}/>}
				</li>
				
			</React.Fragment>
			);
		})}
		</ul>


		{isModalVisible && <ModalLayout 
		 				isModalVisible={isModalVisible}
						className='background'
						children={<AskoeModal setIsModalAskoeVisible={setIsModalVisible} isModal={isModalVisible} item={poindData}/>} 
  		/>}
	</>
)}

function mapStateToProps(state) {
	const 
		  {filters} = state.filters;

	return {
	  filters
	};
  }
	
	
  export default connect(mapStateToProps)(AskoeList);
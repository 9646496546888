import React, {useState, useEffect} from 'react';
import OrderItem from '../../components/blocks/OrderItem.jsx';
import SelectBlock from '../../components/blocks/SelectBlock.jsx';
import {ReactComponent as Filter} from "../../assets/filter.svg";
import { connect } from "react-redux";
import _ from "lodash";
import {  cleanFilteredItems, getFilteredItems } from "../../store/actions/filters";
import {GET_ITEMS} from "../../store/actions/types";


const OrderEnergyGeneral = ({userInfo, filters, filteredItems, dispatch, options, setSearchContract, searchContract, items, averagePriceDay, setIsModalVisible}) => {
	const [orderByContract, setOrderByContract] = useState(null)
	
	useEffect(() => {
		dispatch(cleanFilteredItems(orderByContract))

		dispatch({
				type: GET_ITEMS,
				payload: { items: userInfo.company.contracts}
		})
		
		setOrderByContract(items)	
	}, [items]);
	

	useEffect(() => {
		if(orderByContract && filters !==null ){
			dispatch(getFilteredItems(orderByContract, filters))
		}
	}, [filters, orderByContract ]);

	const handlerRemove = () => {
		setSearchContract(null)
		dispatch(cleanFilteredItems(items));
	};
	

  return (
    <div className="order-energy">
		<div className="separator"></div>
		<div className="select-block" >
			<div className="select-block__filter" onClick={()=> setIsModalVisible(true)}> <span><Filter/></span>Фільтри</div>
				<div className="select-block__item">
					<SelectBlock items = {
								options && options.map(item=> ({ label: `Договір № ${item.contractNumber}`, value: item.contractNumber }))	
								}
								value={searchContract}
								placeholder='Договір'
								onChange = {(selected)=> setSearchContract({label:`№ ${selected.value}`, value: selected.value})}
								isDisabled={options && options.length > 0 ? false : true}
					/>
				</div>
			<div className={filters ? "select-block__clear" : "select-block__clear select-block__clear--empty"} onClick = {handlerRemove}> <span  className="select-block__close"></span> Очистити фільтр</div>
		</div>
		{orderByContract && filteredItems && filteredItems.map((item, i) => {
			return (<OrderItem  key={i} contract={item} averagePriceDay={averagePriceDay} />)
		})}
			
	</div>
	
	
  );
};


function mapStateToProps(state) {
	const  {userInfo, items} = state.userInfo,
		   {filteredItems} = state.userInfo,
		   {filters} = state.filters;
	  return {
		items,
		filteredItems,
		filters,
		userInfo
		}
}

export default connect(mapStateToProps)(OrderEnergyGeneral);
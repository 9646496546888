import { GET_AVERAGE_PRICE, GET_AVERAGE_PRICE_HOURLY } from "../actions/types";

const initialState = { averagePrice: [] };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_AVERAGE_PRICE:
      return {
        ...state,
        averagePrice: payload.averagePrice,
      };

    case GET_AVERAGE_PRICE_HOURLY:
      return {
        ...state,
        averagePriceHourly: payload.averagePriceHourly,
      };

    default:
      return state;
  }
}

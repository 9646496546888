import React, { useState, useEffect, useMemo } from "react";
import CalendarBlock from "../../components/blocks/Calendar";
import ChartTarrifStructure from "../../components/blocks/ChartTarrifStructure";
import moment from "moment";
import Button from "../../UI/Button";
import SkeletonRect from "../../components/blocks/SkeletonRect";

import DatePickerSelect from "../../components/blocks/DatePickerSelect";
import { connect } from "react-redux";
import {
  getConsumptionPerDay,
  getConsumptionPerMonth,
  getGeneralConsumptionPerMonth,
} from "../../store/actions/userInfo";
import { cleanFilteredItems } from "../../store/actions/filters";
import { read, utils, writeFile } from "xlsx";

const TariffStructureContent = ({ dispatch, groupB, filters, items }) => {
  const [consumptionFact, setConsumptionFact] = useState(null);
  const [consumptionCost, setConsumptionCost] = useState(null);
  const [generalConsumption, setGeneralConsumption] = useState(0);

  const [controlSection, setControlSection] = useState(true);

  const [calendarMonth, setCalendarMonth] = useState(moment());
  const [num, setNum] = useState(null);
  const [firstDay, setFirstDay] = useState(null);
  const [lastDay, setLastDay] = useState(null);
  const [daysRange, setDaysRange] = useState([]);
  const monthRange = useMemo(() => {
    return [
      "січ",
      "лют",
      "бер",
      "квіт",
      "трав",
      "черв",
      "лип",
      "серп",
      "вер",
      "жовт",
      "лист",
      "груд",
    ];
  }, []);

  useEffect(() => {
    dispatch(cleanFilteredItems());
    setCalendarMonth(moment());
  }, [controlSection]);

  useEffect(() => {
    if (groupB) {
      if (filters?.yearDate) {
        return (
          setFirstDay(
            moment(filters.yearDate).startOf("year").format("YYYY-MM-DD")
          ),
          setLastDay(
            moment(filters.yearDate).endOf("year").format("YYYY-MM-DD")
          )
        );
      }

      return (
        setFirstDay(moment().startOf("year").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("year").format("YYYY-MM-DD"))
      );
    } else {
      if (filters?.endDate) {
        return (
          setFirstDay(moment(filters.startDate).format("YYYY-MM-DD")),
          setLastDay(moment(filters.endDate).format("YYYY-MM-DD"))
        );
      }
      return (
        setFirstDay(moment().startOf("month").format("YYYY-MM-DD")),
        setLastDay(moment().endOf("month").format("YYYY-MM-DD"))
      );
    }
  }, [filters, controlSection, groupB]);

  const switchSector = () => {
    setControlSection((prevSection) => !prevSection);
  };

  useEffect(() => {
    if (num !== null) {
      setCalendarMonth(calendarMonth.add(num, "M"));
      setFirstDay(moment(calendarMonth).startOf("month").format("YYYY-MM-DD"));
      setLastDay(moment(calendarMonth).endOf("month").format("YYYY-MM-DD"));
      setNum(null);
    }
  }, [num]);

  useEffect(() => {
    if (lastDay) {
      if (groupB) {
        dispatch(getGeneralConsumptionPerMonth());
        dispatch(getConsumptionPerMonth(firstDay, lastDay)).then((res) => {
          const { amount, cost } = res;
          setConsumptionFact(amount);
          setConsumptionCost(cost);
        });
      } else {
        dispatch(getConsumptionPerDay(firstDay, lastDay)).then((res) => {
          const { amount, cost } = res;
          setConsumptionFact(amount);
          setConsumptionCost(cost);
          setGeneralConsumption(
            (Math.round(amount?.reduce((a, b) => a + b, 0)) * 100) / 100
          );
        });
      }
    }

    let computedDates = [];
    let startingMoment = moment(firstDay);
    while (startingMoment <= moment(lastDay)) {
      computedDates.push(startingMoment.format("DD.MM"));
      startingMoment.add(1, "days");
    }
    setDaysRange(computedDates);
  }, [lastDay, controlSection, groupB]);

  const handleExport = () => {
    const exportMonth = [[moment(calendarMonth).format("MMMM-YYYY")]];
    const xlsHeadings = [
      [""].concat(
        days.map((day) => day + "." + moment(calendarMonth).format("MM"))
      ),
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, exportMonth);
    utils.sheet_add_aoa(ws, xlsHeadings, { origin: "A2", skipHeader: true });
    utils.sheet_add_aoa(ws, [["Вартість, кВт*год"].concat(consumptionCost)], {
      origin: "A3",
      skipHeader: true,
    });
    utils.sheet_add_aoa(ws, [["Споживання, факт"].concat(consumptionFact)], {
      origin: "A4",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Consumption");
    writeFile(
      wb,
      `Споживання та Вартість за ${moment(calendarMonth).format(
        "MMMM-YYYY"
      )}.xlsx`
    );
  };

  if (groupB === null) {
    return <SkeletonRect />;
  }

  return (
    <div className="tariff-structure">
      <div className="tariff-structure__heading">
        <div className="tariff-structure__headings-wrap">
          <div
            className={
              controlSection
                ? "tariff-structure__heading-left tariff-structure__heading-left--chart "
                : "tariff-structure__heading-left"
            }
          >
            <div
              className={
                controlSection
                  ? "tariff-structure__title tariff-structure__title--chart"
                  : "tariff-structure__title "
              }
            >
              <div className="tariff-structure__title-wrap">
                {"Щодобова вартість електроенергії за"}
              </div>
            </div>

            {controlSection ? (
              <>
                {groupB ? (
                  <DatePickerSelect
                    items={items}
                    switchState={true}
                    placeholder="Рік"
                  />
                ) : (
                  <DatePickerSelect
                    datepicker
                    switchState={false}
                    placeholder="Період"
                    setEventsSelect={"auto"}
                    noDisabled={true}
                  />
                )}
              </>
            ) : (
              <div className="calendar__heading">
                <div className="calendar__heading-wrap">
                  <div
                    className="calendar__heading-control calendar__heading-control--prev"
                    onClick={() => setNum(-1)}
                  >
                    <div className="icon-arrow-calendar icon-arrow-calendar--prev"></div>
                  </div>
                  <div className="calendar__heading-month">
                    {calendarMonth.format("MMMM YYYY")}
                  </div>
                  <div
                    className="calendar__heading-control calendar__heading-control--next"
                    onClick={() => setNum(1)}
                  >
                    <div className="icon-arrow-calendar icon-arrow-calendar--next"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="tariff-structure__controls ">
              <div
                className={
                  controlSection
                    ? "general-info__controls-section--active"
                    : "general-info__controls-section"
                }
                onClick={switchSector}
              >
                Графік
              </div>
              {!groupB && (
                <>
                  <div className="general-info__separator"></div>
                  <div
                    className={
                      !controlSection
                        ? "general-info__controls-section--active"
                        : "general-info__controls-section"
                    }
                    onClick={switchSector}
                  >
                    Таблиця
                  </div>
                </>
              )}
            </div>
          </div>
          {window.innerWidth >= 768 && (
            <div className="tariff-structure__heading-right">
              {!controlSection ? (
                <div className="tariff-structure__heading-info">
                  Загальне споживання:
                  <p>
                    {generalConsumption
                      ? generalConsumption
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : "0"}
                    {" кВт*год"}
                  </p>
                </div>
              ) : null}

              {!controlSection ? (
                <Button
                  text="Завантажити аналітичні матеріали в Exсel "
                  className="button button__excel"
                  sendRequest={handleExport}
                ></Button>
              ) : null}
            </div>
          )}
        </div>
      </div>

      {controlSection ? (
        <ChartTarrifStructure
          className="chart__main"
          button="Експортувати дані"
          selectedPeriod={num}
          xAvis={!groupB ? daysRange : monthRange}
          groupB={groupB}
          consumption={consumptionFact}
          cost={consumptionCost}
          title={`Cпоживання та Вартість ${moment(calendarMonth).format(
            "MMMM-YYYY"
          )}`}
        />
      ) : (
        <CalendarBlock
          month={calendarMonth}
          cost={consumptionCost}
          consumption={consumptionFact}
        />
      )}

      {window.innerWidth < 768 && (
        <div className="tariff-structure__heading-right">
          {!controlSection ? (
            <div className="tariff-structure__heading-info">
              Загальне споживання:
              <p>
                {generalConsumption
                  ? generalConsumption
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  : "0"}
                кВт*год
              </p>
            </div>
          ) : null}

          {!controlSection ? (
            <Button
              text="Експортувати дані"
              className="button button__excel"
              sendRequest={handleExport}
            ></Button>
          ) : null}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo, groupB, items } = state.userInfo,
    { filters } = state.filters;

  return {
    userInfo,
    groupB,
    filters,
    items,
  };
}

export default connect(mapStateToProps)(TariffStructureContent);

import React, { useState } from "react";
import Button from "../../../UI/Button";
import { Link } from "react-router-dom";
import Icon from "../../../UI/Icon";
import plus from "../../../assets/plus.svg";
import { Form, Input } from "antd";
import { ReactComponent as TooltipInfo } from "../../../assets/tooltipInfo.svg";
import { IMaskInput } from "react-imask";
import ModalLayout from "../../../template/ModalLayout";
import ErrorModal from "../../../components/modals/ErrorModal";

import { connect } from "react-redux";
import { register } from "../../../store/actions/auth";

const ContentSignUp = ({ dispatch }) => {
  const [form] = Form.useForm();
  const [signupSuccessful, setSignupSuccessful] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState("Обов`язкове поле");
  const PhoneMask = "+{38}{\\0}(00) 000 00 00";

  const handlerSubmit = (e) => {
    setButtonDisable(true);

    form
      .validateFields()
      .then((values) => {
        let password = Math.random().toString(36).slice(-8);
        values["password"] = password;
        values["username"] = values["email"];
        dispatch(register(values))
          .then(() => {
            setSignupSuccessful(true);
          })
          .catch((error) => {
			setIsModalVisible(true);
            setButtonDisable(false);
          });
      })
      .catch((error) => {
        setButtonDisable(false);
      });
  };

  const changeFirstLetter = (e) => {
    form.setFieldsValue({
      [e.target.name]:
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
    });
  };

  return (
	  <>
    <div className="registration__right">
      {!signupSuccessful && (
        <div className="registration__block--form">
          <div className="form">
            <Icon img={plus} className="icon" />
            <div className="form__title">Заповніть наступні поля</div>

            <Form form={form} onFinish={handlerSubmit} name="sign_up">
              <div className="form__field">
                <div className="form__field-user">
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: error },
                      {
                        pattern:
                          /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                        message: "Невірний формат",
                      },
                    ]}
                  >
                    <Input
                      name="lastName"
                      placeholder="Прізвище"
                      onChange={changeFirstLetter}
                    />
                  </Form.Item>
                </div>
                <div className="form__field-user">
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: error },
                      {
                        pattern:
                          /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                        message: "Невірний формат",
                      },
                    ]}
                  >
                    <Input
                      name="firstName"
                      placeholder="Ім’я"
                      onChange={changeFirstLetter}
                    />
                  </Form.Item>
                </div>
                <div className="form__field-user">
                  <Form.Item
                    name="middleName"
                    rules={[
                      { required: true, message: error },
                      {
                        pattern:
                          /^([^\s])([^A-z])([А-я'-єЄіІїЇёЁґҐ"'`\s]{1,})$/,
                        message: "Невірний формат",
                      },
                    ]}
                  >
                    <Input
                      name="middleName"
                      placeholder="По батькові"
                      onChange={changeFirstLetter}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form__field-company">
                <Form.Item
                  name="companyName"
                  rules={[
                    { required: true, message: error },
                    {
                      pattern:
                        /^([^\s])([А-я0-9'-єЄіІїЇёЁґҐ"'`!#$%&*+/=?^{|}~._\s)]{1,})$/,
                      message: "Невірний формат",
                    },
                  ]}
                >
                  <Input
                    name="companyName"
                    placeholder="Введіть назву компанії"
                    onChange={changeFirstLetter}
                  />
                </Form.Item>
              </div>
              <div className="form__field">
                <div className="form__field-contact">
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: error },
                      {
                        pattern:
                          /^([^\.])(?!.*\.\.)([A-z0-9_\\.-]{1,})@([A-z0-9_.-]{1,})*\.([A-z]{2,8})$/,
                        message: "Невірний формат",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Введіть Email"
                      suffix={
                        <>
                          <span className="tooltip">
                            <TooltipInfo />
                          </span>
                          <div className="tooltip__text">
                            Вказаний email буде використовуватись під час входу
                            у ваш особистий кабінет
                          </div>
                        </>
                      }
                    />
                  </Form.Item>
                </div>
                <div className="form__field-contact">
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: error },
                      {
                        pattern: /^\+380\((\d+)\)\s\d{3}\s\d{2}\s\d{2}$/,
                        message: "+{380}(XX) XXX XX XX",
                      },
                    ]}
                  >
                    <IMaskInput
                      type="tel"
                      className="form-control"
                      mask={PhoneMask}
                      value=""
                      placeholder="Введіть номер телефону"
                    />
                  </Form.Item>
                </div>
              </div>

              <Button
                disabled={buttonDisable}
                text="Відправити запит"
                type="submit"
                className=" button button__submit"
              />
            </Form>
          </div>
        </div>
      )}

      {signupSuccessful && (
        <div className="registration__right">
          <div className="registration__successful">
            <div className="registration__successful-wrap">
              <div className="registration__successful-title">
                Дякуємо за ваш запит
              </div>
              <div className="registration__successful-text">
                Незабаром наш менеджер зв’яжеться з Вами.
              </div>
              <Link
                to="/verification"
                role="button"
                className="button button__empty"
              >
                На головну
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>

		{isModalVisible && (
          <ModalLayout
            isModalVisible={isModalVisible}
            className="background"
            children={
              <ErrorModal
                setIsModalRequestVisible={setIsModalVisible}
                text="Данний email вже має обліковий запис"
              />
            }
          />
        )}
	</>
  );
};

export default connect()(ContentSignUp);

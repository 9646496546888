import React, {useEffect} from 'react';
import MainLayout from '../../template/MainLayout';
import FilterBlockDesktop from '../../components/blocks/FilterBlockDesktop';
import AskoeList from '../../components/blocks/AskoeList';
import { connect } from "react-redux";
import { askoePointsInfo } from "../../store/actions/userInfo";
import { cleanFilteredItems } from "../../store/actions/filters";



const Askoe = ({userInfo, askoePoints, dispatch}) => {
	
	useEffect(() => {
		if (userInfo?.company) {
			dispatch(askoePointsInfo(userInfo.company.id))
		}

		dispatch(cleanFilteredItems())
	}, [])
	

  return (
    <MainLayout title='Споживання та дані АСКОЕ' text='Тут міститься детальна інформація за рахунками та аналітика споживання за всіма договорами.'>
      <div className="askoe">
		  <div className="askoe__wrap">
			<div className="askoe__top">
				<div className="askoe__title">Інформація за точками обліку</div>
				{/* <FilterBlockDesktop items={items} askoe/> */}
			</div>
			<div className="askoe__content">
				<AskoeList askoePoints={askoePoints} />
			</div>			
		  </div>
	  </div>
    </MainLayout>
  );
};

function mapStateToProps(state) {
	const {userInfo} = state.userInfo,
		  {items} = state.userInfo,
		  {askoePoints} = state.userInfo;
		
	  return {
		userInfo,
		askoePoints,
		items
		}
}

export default connect(mapStateToProps)(Askoe);
import {
	GET_FILTERS,
	GET_ITEMS,
	GET_FILTERED_ITEMS,

  } from "../actions/types";
import moment from  'moment';




export const  getFilters = (filters) => (dispatch) => {
	return  (
			dispatch({
				type: GET_FILTERS,
				payload: {filters:filters},
				})
	)			
};

export const  getFilteredItems = (items, filters) => (dispatch) => {
	
	let filteredItems = items;

	if(filters == null) filteredItems;


	if(filters !== null && filters.searchContract && filteredItems!== null ) {
			filteredItems = filteredItems.filter(item => {
				if(item.contract){
					return item.contract.contractNumber  === filters.searchContract.value
				}else{
					return item.contractNumber === filters.searchContract.value
				}
			})
				
	};

	if(filters !== null && filters.yearDate) {
		filteredItems = filteredItems.filter(item => (new Date(item.date).getYear() || new Date(item.documentDate).getYear()) === new Date(filters.yearDate).getYear() );
	};
	

	if(filters !== null && filters.startDate && filters.endDate) {
		filteredItems = filteredItems.filter(item => (new Date(item.date).setHours(0) || new Date(item.documentDate).setHours(0)) >= new Date(filters.startDate).setHours(0) && (new Date(item.date).setHours(0) || new Date(item.documentDate).setHours(0)) <= new Date(filters.endDate).setHours(0));
	};


	if(filters !== null && filters.searchType) {
		filteredItems = filteredItems.filter(item => item.paymentType === filters.searchType.value)
	};

	return  (
		
			dispatch({
				type: GET_FILTERED_ITEMS,
				payload: { filteredItems},
			})
	)			
};


export const cleanFilteredItems = (items) => (dispatch) => {
	if(items)
		dispatch({
			type: GET_ITEMS,
			payload: { items: items, filteredItems: items},
		})
	
	return  (	
			dispatch({
				type: GET_FILTERS,
				payload: { filters: null},
			})

	)		
		
};
import React from 'react'

export default function Button({text, className, sendRequest, img, disabled}) {

    if(img) {
        return (
        <button className={className}>
            <img src={img} alt="" />
        </button>
        )
    }

    return (
        <button className={className} onClick={sendRequest} disabled={disabled} >
            {text}
        </button>
    )
}
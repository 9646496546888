import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/arrow-calendar.svg";
import { ReactComponent as Arrow2 } from "../../assets/arrow2.svg";
import ReactPaginate from "react-paginate";
import SkeletonRect from "./SkeletonRect";

import moment from "moment";

const TableInvoices = ({ data, className, headings, payment }) => {
  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + 5;
      setCurrentItems(data.slice(itemOffset, endOffset));

      setPageCount(Math.ceil(data.length / 5));
    }
  }, [itemOffset, data]);

  useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % data.length;

    setItemOffset(newOffset);
  };

  if (data === null) {
    return <SkeletonRect />;
  }

  return (
    <>
      <div className={className}>
        <table className="table-invoices">
          <thead>
            <tr>
              {headings.map((date, i) => (
                <th key={i} className="table-invoices__th">
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-invoices__body">
            {currentItems &&
              currentItems.map((item, i) => {
                return (
                  <tr key={i}>
                    {payment ? (
                      <>
                        <th className="table-invoices__th">
                          {item.paymentType === "income"
                            ? "Нарахування"
                            : "Списання"}
                        </th>
                        <td className="table-invoices__td">
                          {moment(item.date).format("DD.MM.YY")}{" "}
                        </td>
                        <td className="table-invoices__td">
                          {item.contract.contractNumber}
                        </td>
                        <td className="table-invoices__td">
                          {item.paymentInvoice}{" "}
                        </td>
                        <td className="table-invoices__td">
                          {item.paymentDescription}{" "}
                        </td>
                        <td className="table-invoices__td">
                          {item.paymentAmount
                            .toFixed(2)
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                          грн
                        </td>
                      </>
                    ) : (
                      <>
                        <th className="table-invoices__th">
                          {moment(item.date).format("DD.MM.YY")}
                        </th>
                        <td className="table-invoices__td">
                          {item.contract.contractNumber}{" "}
                        </td>
                        <td className="table-invoices__td">{item.name}</td>
                        <td className="table-invoices__td">
                          {item.amount
                            .toFixed(2)
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""}{" "}
                          грн
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <ReactPaginate
        className="pagination"
        nextLabel={<Arrow />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={<Arrow2 />}
        marginPagesDisplayed={3}
        forcePage={currentPage}
      />
    </>
  );
};

export default TableInvoices;

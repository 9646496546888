import {
	GET_FILTERS,
	GET_FILTERS_ASKOE,
	GET_FILTERS_ASKOE_SWITCH
  } from "../actions/types";
 

	const initialState = 
	{filters: null,
	checked: true,
	consumptionSelected: 'A+'
	};
	
  export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {

	  case GET_FILTERS:
		return {
		  ...state,
		  filters: payload.filters
	  };

	  case GET_FILTERS_ASKOE:
		return {
		  ...state,
		  consumptionSelected: payload.consumptionSelected
	  };


	  case GET_FILTERS_ASKOE_SWITCH:
		return {
		  ...state,
		  checked: payload.checked,
	  };


	  
	  default:
		return state;
	}
  }
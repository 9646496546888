import React from "react";


const SidebarStep = () => {
  return (
    <div className="registration__left">
      <div className="registration__welcome">Вітаємо<br/> у KNESS Energy</div>
      <div className="registration__left-content">
        <div className="registration__list-title">В особистому кабінеті Ви можете:</div>
        <ul className="registration__list">
          <li className="registration__list-item">відслідковувати споживання енергії</li>
          <li className="registration__list-item">переглядати та тримати під рукою ключові умови договору</li>
          <li className="registration__list-item">орієнтуватися в актуальних тарифах і цінах на електроенергію</li>
          <li className="registration__list-item">контролювати оплати та інші операції</li>
          <li className="registration__list-item">працювати з рахунками та актами</li>
        </ul>
      </div>
    </div>
  )
};

export default SidebarStep;

import React, { useEffect, useState } from "react";
import TableInvoices from "../../components/blocks/TableInvoices";
import { connect } from "react-redux";
import { getPaymentsInfo } from "../../store/actions/userInfo";
import FilterBlockDesktop from "../../components/blocks/FilterBlockDesktop";
import { cleanFilteredItems } from "../../store/actions/filters";

const Payments = ({ userInfo, filteredItems, dispatch }) => {
  const tableHeadings = [
    "Тип рахунку",
    "Дата",
    "Договір",
    "Рахунок",
    "Призначення платежу",
    "Сума",
  ];
  const [paymentTableItems, setPaymentTableItems] = useState(null);

  useEffect(() => {
    if (userInfo) {
      dispatch(getPaymentsInfo(userInfo.company.id)).then((response) => {
        setPaymentTableItems(response.data);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(cleanFilteredItems(paymentTableItems));
  }, []);

  return (
    <div className="documents">
      <div className="documents__wrap">
        <div className="documents__title">Інформація за рахунками</div>
        <FilterBlockDesktop
          items={paymentTableItems}
          paymentType="false"
          datepicker="false"
          placeholder="Рік і місяць"
        />
        <TableInvoices
          className="table__general"
          data={
            paymentTableItems &&
            filteredItems.sort((a, b) => new Date(b.date) - new Date(a.date))
          }
          headings={tableHeadings}
          payment
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo,
    { filteredItems } = state.userInfo;

  return {
    userInfo,
    filteredItems,
  };
}

export default connect(mapStateToProps)(Payments);

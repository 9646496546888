const requestsUrl = {
    url: process.env.REACT_APP_API_HOST,  
    type: {
        auth:  '/api/auth',
    }
};





export {requestsUrl};


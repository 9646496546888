import { useEffect } from "react";
import ContractCard from "../../components/blocks/ContractCard";
import { getGeneralConsumptionPerMonth } from "../../store/actions/userInfo";
import { connect } from "react-redux";

const GeneralInfoSeparateContracts = ({ userInfo, dispatch }) => {
  useEffect(() => {
    dispatch(getGeneralConsumptionPerMonth());
  }, []);
  return (
    <>
      {userInfo &&
        userInfo.company.contracts.map((contract, i) => (
          <ContractCard
            key={contract.id}
            contract={contract}
            indexContract={i}
            groupB={contract.groupB}
          />
        ))}
    </>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}
export default connect(mapStateToProps)(GeneralInfoSeparateContracts);

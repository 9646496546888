import React, { useEffect, useState } from "react";
import TableDiscount from "../../components/blocks/TableDiscount";
import DatePickerSelect from "../../components/blocks/DatePickerSelect";
import { connect } from "react-redux";
import { getDiscountInfo } from "../../store/actions/userInfo";

import { getFilteredItems } from "../../store/actions/filters";

const GeneralInfoDiscount = ({
  userInfo,
  filteredItems,
  dispatch,
  filters,
}) => {
  const [discountItems, setDiscountItems] = useState(null);
  const tableHeadings = [
    "Місяць",
    "Договір",
    "Точка доступу",
    "Сума нарахування",
    "Обсяг заощадження",
  ];

  useEffect(() => {
    if (userInfo) {
      dispatch(getDiscountInfo(userInfo.company.id)).then((response) => {
        setDiscountItems(response.data);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (filters && discountItems) {
      dispatch(getFilteredItems(discountItems, filters));
    }
  }, [filters]);

  return (
    <div className="general-info__discount">
      <div className="general-info__discount-wrap">
        <div className="general-info__discount-select">
          <div className="select-block">
            <div className="select-block__item">
              <DatePickerSelect
                items={discountItems}
                switchState={true}
                placeholder="Рік"
              />
            </div>
          </div>
        </div>

        <TableDiscount
          className="table__general"
          year
          data={
            discountItems &&
            filteredItems.sort((a, b) => new Date(a.date) - new Date(b.date))
          }
          headings={tableHeadings}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { filteredItems } = state.userInfo,
    { filters } = state.filters;
  return {
    filteredItems,
    filters,
  };
}

export default connect(mapStateToProps)(GeneralInfoDiscount);

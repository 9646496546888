import { GET_AVERAGE_PRICE, GET_AVERAGE_PRICE_HOURLY } from "../actions/types";
import moment from "moment";

export const getAveragePricePerDate = (item, coefficient) => (dispatch) => {
  const averagePrice = item
    .map((price) =>
      price.hourlyPrice.map((item) => item.price).reduce((a, b) => a + b)
    )
    .map((price) => +((price * coefficient) / 24 / 1000).toFixed(3));
  return dispatch({
    type: GET_AVERAGE_PRICE,
    payload: { averagePrice: averagePrice },
  });
};

export const getAveragePricePerHourly = (item, coefficient) => (dispatch) => {
  const averagePrice = _.sortBy(item, "date")
    .map((price) => price.hourlyPrice)
    .flat()
    .reduce((obj, arr) => {
      obj[arr.hour]
        ? (obj[arr.hour].price += arr.price)
        : (obj[arr.hour] = { hour: arr.hour, price: arr.price });
      return obj;
    }, {});
  const averagePriceHourly = Object.values(averagePrice).map((item) =>
    ((item.price * coefficient) / 1000).toFixed(3)
  );

  return dispatch({
    type: GET_AVERAGE_PRICE_HOURLY,
    payload: { averagePriceHourly: averagePriceHourly },
  });
};

export const getSortedData = (item) => {
  return item
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((item) => {
      let consumption = item.consumptionAPlus
        .map((item) => item.consumption)
        .reduce((a, b) => a + b);
      return { date: item.date, consumption: consumption };
    });
};

export const getGroupedDataByDate = (data, period) => {
  const sortedData = _.sortBy(data, "date");

  return sortedData.reduce((obj, arr) => {
    const date =
      period === "month"
        ? moment(arr.date).format("YYYY-MM")
        : moment(arr.date).format("MM-DD");
    obj[date]
      ? ((obj[date].cost += arr.cost), (obj[date].amount += arr.amount))
      : (obj[date] = {
          cost: arr.cost,
          amount: arr.amount,
        });
    return obj;
  }, {});
};

export const getComputedDataPerPeriod = (data, period) => {
  const dataPerMonth = period.reduce((obj, arr) => {
    if (Object.keys(data).indexOf(arr) >= 0) {
      const index = Object.keys(data).indexOf(arr);
      return {
        ...obj,
        [arr]: Object.values(data)[index],
      };
    }
    return {
      ...obj,
      [arr]: { cost: 0, amount: 0 },
    };
  }, {});

  const cost = Object.values(dataPerMonth).map((item) =>
    parseFloat(item.cost.toFixed(2))
  );
  const amount = Object.values(dataPerMonth).map((item) => item.amount);

  return { cost, amount };
};

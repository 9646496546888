import React, { useState, useEffect, useMemo } from "react";
import MainLayout from "../../template/MainLayout";
import TabBlock from "../../components/blocks/Tab";
import DocumentsBill from "./DocumentsBill";
import DocumentsActs from "./DocumentsActs";
import DocumentsAddContracts from "./DocumentsAddContracts";
import DocumentsContracts from "./DocumentsContracts";
import { getDocumentsInfo } from "../../store/actions/userInfo";
import { connect } from "react-redux";

const Documents = ({ userInfo, dispatch }) => {
  const [documentsBill, setDocumentsBill] = useState(null);
  const [documentsAct, setDocumentsAct] = useState(null);
  const [documentsWaybill, setDocumentsWaybill] = useState(null);

  useMemo(() => {
    if (userInfo?.company) {
      return dispatch(getDocumentsInfo(userInfo.company.id)).then(
        (response) => {
          setDocumentsBill(
            response.data.filter((item) => item.documentType === "invoice")
          );
          setDocumentsAct(
            response.data.filter((item) => item.documentType === "act")
          );
          setDocumentsWaybill(
            response.data.filter((item) => item.documentType === "waybill")
          );
        }
      );
    }
    return null;
  }, []);

  return (
    <MainLayout
      title="Документи"
      text="Ви можете переглянути, завантажити або роздрукувати ці документи."
    >
      <TabBlock
        tabs={[
          {
            tab: "Рахунки",
            сontent: <DocumentsBill documentsTableItems={documentsBill} />,
          },
          {
            tab: "Акти звіряння",
            сontent: <DocumentsActs documentsTableItems={documentsAct} />,
          },
          {
            tab: "Акти виконаних робіт",
            сontent: (
              <DocumentsAddContracts documentsTableItems={documentsWaybill} />
            ),
          },
          // {tab: "Договори", сontent: <DocumentsContracts/>},
        ]}
        className="react-tabs__tab"
      ></TabBlock>
    </MainLayout>
  );
};

function mapStateToProps(state) {
  const { userInfo } = state.userInfo;

  return {
    userInfo,
  };
}

export default connect(mapStateToProps)(Documents);
